import { transform } from "ol/proj";
import { wgs84, epsg3857 } from "./Types.js";
import { isNullOrWhiteSpace } from "./fable_modules/fable-library.4.5.0/String.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.4.5.0/Choice.js";
import { tryHead, indexed, fold, append, foldBack } from "./fable_modules/fable-library.4.5.0/Array.js";
import { singleton, cons as cons_1, reverse, map } from "./fable_modules/fable-library.4.5.0/List.js";
import { round } from "./fable_modules/fable-library.4.5.0/Util.js";
import { singleton as singleton_1 } from "./fable_modules/fable-library.4.5.0/AsyncBuilder.js";
import { servicesApi, proximityApi } from "./Remoting.js";
import { some, defaultArg, map as map_1, bind, flatten } from "./fable_modules/fable-library.4.5.0/Option.js";
import { Hook_createDisposable_3A5B6456 } from "./fable_modules/Fable.Lit.1.4.2/Hook.fs.js";
import { op_Subtraction, month, year, create, dayOfWeek, day as day_1 } from "./fable_modules/fable-library.4.5.0/Date.js";
import { clamp } from "./fable_modules/fable-library.4.5.0/Double.js";
import { totalDays } from "./fable_modules/fable-library.4.5.0/TimeSpan.js";
import { fold as fold_1, tryLast } from "./fable_modules/fable-library.4.5.0/Seq.js";

export function dimap(f, a, b) {
    return [f(a), f(b)];
}

export function toWgs84(x, y) {
    return transform([x, y], epsg3857, wgs84);
}

export function toEpsg3857(x, y) {
    return transform([x, y], wgs84, epsg3857);
}

export function coordToWgs84(coord) {
    return transform(coord, epsg3857, wgs84);
}

export function coordToEpsg3857(coord) {
    return transform(coord, wgs84, epsg3857);
}

export const coordToWgs84Pos = (arg) => {
    const coord_2 = coordToWgs84(arg);
    return [coord_2[0], coord_2[1]];
};

export const coordToEpsg3857Pos = (arg) => {
    const coord_2 = coordToEpsg3857(arg);
    return [coord_2[0], coord_2[1]];
};

export function mercatorScaleFactor(lat) {
    let d;
    return 1 / ((d = ((lat * 3.141592653589793) / 180), Math.cos(d)));
}

/**
 * Helper function for testing whether a string is null or only whitespace
 */
export function tryStr(str) {
    if (isNullOrWhiteSpace(str)) {
        return void 0;
    }
    else {
        return str;
    }
}

export function tryGetElemRect(id) {
    const elem = document.getElementById(id);
    if (elem == null) {
        return void 0;
    }
    else {
        return elem.getBoundingClientRect();
    }
}

export function Result_apply(fRes, xRes) {
    let matchResult, f, x;
    const copyOfStruct = fRes;
    if (copyOfStruct.tag === 0) {
        const copyOfStruct_1 = xRes;
        if (copyOfStruct_1.tag === 0) {
            matchResult = 0;
            f = copyOfStruct.fields[0];
            x = copyOfStruct_1.fields[0];
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0:
            return new FSharpResult$2(0, [f(x)]);
        default:
            return new FSharpResult$2(1, [void 0]);
    }
}

export function Result_mapError(f, res) {
    if (res.tag === 1) {
        return new FSharpResult$2(1, [f(res.fields[0])]);
    }
    else {
        return new FSharpResult$2(0, [res.fields[0]]);
    }
}

export function Array_op_LessMultiplyGreater() {
    return (fRes) => ((xRes) => Result_apply(fRes, xRes));
}

export function Array_notEmpty(arr) {
    return !(arr.length === 0);
}

export function Array_traverseResult(f, array) {
    return foldBack((head_1, tail_1) => Array_op_LessMultiplyGreater()(Array_op_LessMultiplyGreater()(new FSharpResult$2(0, [(head) => ((tail) => append([head], tail))]))(f(head_1)))(tail_1), array, new FSharpResult$2(0, [[]]));
}

export function Array_sequenceResults(x) {
    return Array_traverseResult((x_1) => x_1, x);
}

export function depthsToCommon5m(depths) {
    let x_1;
    const dp = new Float32Array([-5, -10, -15, -25, -50, -99999]);
    return map((n_1) => [n_1, ~~(round(depths[n_1] / 5) * 5)], reverse((x_1 = fold((tupledArg, tupledArg_1) => {
        const n = tupledArg[0] | 0;
        const a = tupledArg[1];
        if (tupledArg_1[1] > dp[n]) {
            return [n, a];
        }
        else {
            return [n + 1, cons_1(tupledArg_1[0], a)];
        }
    }, [0, singleton(0)], indexed(depths))[1], cons_1(depths.length - 1, x_1))));
}

export function tryGetNearestNodeAndElement(dataSvc, aid, x, y) {
    return singleton_1.Delay(() => {
        const api = proximityApi(dataSvc);
        return singleton_1.Bind(api.GetNearestNodes(aid, [[x, y]]), (_arg) => singleton_1.Bind(api.GetNearestElements(aid, [[x, y]]), (_arg_1) => {
            const firstNode = flatten(tryHead(_arg));
            const firstElem = flatten(tryHead(_arg_1));
            return singleton_1.Return(bind((node) => map_1((elem) => [node, elem], firstElem), firstNode));
        }));
    });
}

export function handleKeyPress(key, action, unitVar) {
    const handleKeyup = (ev) => {
        const kev = ev;
        if (kev.key === key) {
            action();
        }
    };
    window.addEventListener("keyup", handleKeyup, true);
    return Hook_createDisposable_3A5B6456(() => {
        window.removeEventListener("keyup", handleKeyup, true);
    });
}

export function onEnterOrEscape(onEnter, onEscape, ev) {
    const kev = ev;
    const matchValue = kev.key;
    switch (matchValue) {
        case "Enter": {
            onEnter(ev);
            break;
        }
        case "Escape": {
            onEscape(ev);
            break;
        }
        default:
            0;
    }
}

/**
 * Calculate the ISO week number from a date. Taken from https://weeknumber.com/how-to/javascript since we cannot
 * use https://learn.microsoft.com/en-us/dotnet/api/system.globalization.calendar.getweekofyear?view=net-8.0
 * or https://learn.microsoft.com/en-us/dotnet/api/system.globalization.isoweek?view=net-8.0
 */
export function getWeek(date) {
    const findThursdayInWeek = (day, dayInWeek) => ((day + 3) - ((dayInWeek + 6) % 7));
    const thisWeeksThursday = findThursdayInWeek(day_1(date), dayOfWeek(date)) | 0;
    const thursdayThisWeek = create(year(date), month(date), thisWeeksThursday, 0, 0, 0, 0, 1);
    const weekOne = create(year(date), 1, 4, 0, 0, 0, 0, 1);
    const thursday = findThursdayInWeek(day_1(weekOne), dayOfWeek(weekOne)) | 0;
    return clamp(1 + ~~(totalDays(op_Subtraction(thursdayThisWeek, create(year(date), 1, thursday, 0, 0, 0, 0, 1))) / 7), 1, 52) | 0;
}

export const tryAddIdx = (arg_1) => defaultArg(map_1((y) => (1 + y), tryLast(arg_1)), 1);

/**
 * Given a list of indices, return last idx, or if there's a missing index in the sequence
 */
export function findIdx(idxs) {
    return fold_1((index, idx) => {
        if (index === idx) {
            return (idx + 1) | 0;
        }
        else {
            return index | 0;
        }
    }, 1, idxs);
}

export const tryLastIdx = (arg) => defaultArg(tryLast(arg), 1);

export function initAtlantisSessionUrls() {
    return singleton_1.Delay(() => singleton_1.Bind(servicesApi.GetArchiveService(), (_arg) => {
        const aUrl = _arg;
        console.log(some(`Archmeister: ${aUrl}`));
        sessionStorage["archmeister_url"]=aUrl;
        return singleton_1.Bind(servicesApi.GetFileService(), (_arg_1) => {
            const sUrl = _arg_1;
            console.log(some(`Sorcerer: ${sUrl}`));
            sessionStorage["sorcerer_url"]=sUrl;
            return singleton_1.Zero();
        });
    }));
}

