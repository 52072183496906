import { Record, Union } from "../../fable_modules/fable-library.4.5.0/Types.js";
import { unit_type, list_type, anonRecord_type, bool_type, lambda_type, option_type, class_type, float64_type, tuple_type, array_type, float32_type, record_type, union_type, int32_type } from "../../fable_modules/fable-library.4.5.0/Reflection.js";
import { fold, append } from "../../fable_modules/fable-library.4.5.0/Array.js";
import { Types_DriftersVariant_$reflection } from "../Archmeister/ApiTypes.js";
import { ParticleType_$reflection, GroupType_$reflection, LayerType_$reflection, SedimentKind_$reflection, ParticleState_$reflection, ParticleKind_$reflection, GroupKind_$reflection, FieldKind_$reflection, ApiParticle_$reflection } from "../Drifters/ApiTypes.js";

export class Period extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Year", "Month"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? Period_get_Months()[x.fields[0]] : "Year";
    }
}

export function Period_$reflection() {
    return union_type("Sorcerer.Types.Period", [], Period, () => [[], [["Item", int32_type]]]);
}

export function Period_get_Months() {
    return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
}

export function Period_get_Periods() {
    return append(["Year"], Period_get_Months());
}

export function Period__ToInt(x) {
    if (x.tag === 1) {
        return (x.fields[0] + 1) | 0;
    }
    else {
        return 0;
    }
}

export class StatProp extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Temperature", "Salinity", "Speed", "U", "V", "WaterTransport", "Current", "Undefined"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? "salinity" : ((x.tag === 2) ? "sp" : ((x.tag === 3) ? "u" : ((x.tag === 4) ? "v" : ((x.tag === 5) ? "waterTransport" : ((x.tag === 6) ? "current" : ((x.tag === 7) ? "" : "temp"))))));
    }
}

export function StatProp_$reflection() {
    return union_type("Sorcerer.Types.StatProp", [], StatProp, () => [[], [], [], [], [], [], [], []]);
}

export function StatProp_FromString_Z721C83C5(s) {
    switch (s) {
        case "temp":
            return new StatProp(0, []);
        case "salinity":
            return new StatProp(1, []);
        case "sp":
            return new StatProp(2, []);
        case "u":
            return new StatProp(3, []);
        case "v":
            return new StatProp(4, []);
        case "waterTransport":
            return new StatProp(5, []);
        case "current":
            return new StatProp(6, []);
        default:
            return new StatProp(7, []);
    }
}

export class StatMetric extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Mean", "Std", "Var", "Q05", "Q25", "Q50", "Q75", "Q95", "Q99"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? "Std" : ((x.tag === 2) ? "Var" : ((x.tag === 3) ? "Q05" : ((x.tag === 4) ? "Q25" : ((x.tag === 5) ? "Q50" : ((x.tag === 6) ? "Q75" : ((x.tag === 7) ? "Q95" : ((x.tag === 8) ? "Q99" : "Mean")))))));
    }
}

export function StatMetric_$reflection() {
    return union_type("Sorcerer.Types.StatMetric", [], StatMetric, () => [[], [], [], [], [], [], [], [], []]);
}

export function StatMetric_FromString_Z721C83C5(s) {
    switch (s) {
        case "Std":
            return new StatMetric(1, []);
        case "Var":
            return new StatMetric(2, []);
        case "Q05":
            return new StatMetric(3, []);
        case "Q25":
            return new StatMetric(4, []);
        case "Q50":
            return new StatMetric(5, []);
        case "Q75":
            return new StatMetric(6, []);
        case "Q95":
            return new StatMetric(7, []);
        case "Q99":
            return new StatMetric(8, []);
        default:
            return new StatMetric(0, []);
    }
}

export class V2$1 extends Record {
    constructor(X, Y) {
        super();
        this.X = X;
        this.Y = Y;
    }
}

export function V2$1_$reflection(gen0) {
    return record_type("Sorcerer.Types.V2`1", [gen0], V2$1, () => [["X", gen0], ["Y", gen0]]);
}

export class PlainGrid extends Record {
    constructor(Vertices, Indices) {
        super();
        this.Vertices = Vertices;
        this.Indices = Indices;
    }
}

export function PlainGrid_$reflection() {
    return record_type("Sorcerer.Types.PlainGrid", [], PlainGrid, () => [["Vertices", array_type(float32_type)], ["Indices", array_type(int32_type)]]);
}

export function PlainGrid_get_empty() {
    return new PlainGrid(new Float32Array(0), new Int32Array(0));
}

export class BBox extends Record {
    constructor(minX, maxX, minY, maxY, center) {
        super();
        this.minX = minX;
        this.maxX = maxX;
        this.minY = minY;
        this.maxY = maxY;
        this.center = center;
    }
}

export function BBox_$reflection() {
    return record_type("Sorcerer.Types.BBox", [], BBox, () => [["minX", float32_type], ["maxX", float32_type], ["minY", float32_type], ["maxY", float32_type], ["center", tuple_type(float32_type, float32_type)]]);
}

export function BBox_get_empty() {
    return new BBox(3.4028234663852886E+38, -3.4028234663852886E+38, 3.4028234663852886E+38, -3.4028234663852886E+38, [0, 0]);
}

export class BoundingPolygon extends Record {
    constructor(count, coordinates) {
        super();
        this.count = (count | 0);
        this.coordinates = coordinates;
    }
}

export function BoundingPolygon_$reflection() {
    return record_type("Sorcerer.Types.BoundingPolygon", [], BoundingPolygon, () => [["count", int32_type], ["coordinates", array_type(tuple_type(float32_type, float32_type))]]);
}

export function BoundingPolygon_get_empty() {
    return new BoundingPolygon(0, []);
}

export class ArchiveArea extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Box", "Polygon"];
    }
}

export function ArchiveArea_$reflection() {
    return union_type("Sorcerer.Types.ArchiveArea", [], ArchiveArea, () => [[["Item", BBox_$reflection()]], [["Item", BoundingPolygon_$reflection()]]]);
}

export function ArchiveArea__center(x) {
    if (x.tag === 1) {
        const p = x.fields[0];
        const tupledArg_2 = fold((tupledArg, tupledArg_1) => [tupledArg[0] + tupledArg_1[0], tupledArg[1] + tupledArg_1[1]], [0, 0], p.coordinates);
        const n = p.coordinates.length;
        return [tupledArg_2[0] / n, tupledArg_2[1] / n];
    }
    else {
        return x.fields[0].center;
    }
}

export class Arrow$1 extends Record {
    constructor(MetersPerSecond, Points) {
        super();
        this.MetersPerSecond = MetersPerSecond;
        this.Points = Points;
    }
}

export function Arrow$1_$reflection(gen0) {
    return record_type("Sorcerer.Types.Arrow`1", [gen0], Arrow$1, () => [["MetersPerSecond", float64_type], ["Points", array_type(V2$1_$reflection(gen0))]]);
}

function Api_routeBuilder(system, typeName, methodName) {
    return `/api/v2/${system}/${typeName}/${methodName}`;
}

export const Api_Fvcom_routeBuilder = (typeName) => ((methodName) => Api_routeBuilder("fvcom", typeName, methodName));

export class Api_Fvcom_Archive extends Record {
    constructor(GetTime, GetNLayers, GetPlainGrid, GetNumFrames, GetTimeStep, EquipArchiveCache) {
        super();
        this.GetTime = GetTime;
        this.GetNLayers = GetNLayers;
        this.GetPlainGrid = GetPlainGrid;
        this.GetNumFrames = GetNumFrames;
        this.GetTimeStep = GetTimeStep;
        this.EquipArchiveCache = EquipArchiveCache;
    }
}

export function Api_Fvcom_Archive_$reflection() {
    return record_type("Sorcerer.Types.Api.Fvcom.Archive", [], Api_Fvcom_Archive, () => [["GetTime", lambda_type(class_type("System.Guid"), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [option_type(class_type("System.DateTime"))])))], ["GetNLayers", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [int32_type]))], ["GetPlainGrid", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [PlainGrid_$reflection()]))], ["GetNumFrames", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [int32_type]))], ["GetTimeStep", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [int32_type]))], ["EquipArchiveCache", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [bool_type]))]]);
}

export class Api_Fvcom_Layer extends Record {
    constructor(GetBathymetry, GetSalinity, GetTemperature, GetZeta, GetSpeed, GetUv) {
        super();
        this.GetBathymetry = GetBathymetry;
        this.GetSalinity = GetSalinity;
        this.GetTemperature = GetTemperature;
        this.GetZeta = GetZeta;
        this.GetSpeed = GetSpeed;
        this.GetUv = GetUv;
    }
}

export function Api_Fvcom_Layer_$reflection() {
    return record_type("Sorcerer.Types.Api.Fvcom.Layer", [], Api_Fvcom_Layer, () => [["GetBathymetry", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)]))], ["GetSalinity", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)]))))], ["GetTemperature", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)]))))], ["GetZeta", lambda_type(class_type("System.Guid"), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))], ["GetSpeed", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)]))))], ["GetUv", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(tuple_type(float32_type, float32_type))]))))]]);
}

export class Api_Fvcom_Node extends Record {
    constructor(GetSiglev, GetSiglay, GetArt1, GetBathymetry, GetTemp, GetSalinity) {
        super();
        this.GetSiglev = GetSiglev;
        this.GetSiglay = GetSiglay;
        this.GetArt1 = GetArt1;
        this.GetBathymetry = GetBathymetry;
        this.GetTemp = GetTemp;
        this.GetSalinity = GetSalinity;
    }
}

export function Api_Fvcom_Node_$reflection() {
    return record_type("Sorcerer.Types.Api.Fvcom.Node", [], Api_Fvcom_Node, () => [["GetSiglev", lambda_type(class_type("System.Guid"), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))], ["GetSiglay", lambda_type(class_type("System.Guid"), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))], ["GetArt1", lambda_type(class_type("System.Guid"), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [float32_type])))], ["GetBathymetry", lambda_type(class_type("System.Guid"), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [float32_type])))], ["GetTemp", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)]))))], ["GetSalinity", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)]))))]]);
}

export class Api_Fvcom_Element extends Record {
    constructor(GetSiglev, GetSiglay, GetBathymetry, GetUv, GetSpeed) {
        super();
        this.GetSiglev = GetSiglev;
        this.GetSiglay = GetSiglay;
        this.GetBathymetry = GetBathymetry;
        this.GetUv = GetUv;
        this.GetSpeed = GetSpeed;
    }
}

export function Api_Fvcom_Element_$reflection() {
    return record_type("Sorcerer.Types.Api.Fvcom.Element", [], Api_Fvcom_Element, () => [["GetSiglev", lambda_type(class_type("System.Guid"), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))], ["GetSiglay", lambda_type(class_type("System.Guid"), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))], ["GetBathymetry", lambda_type(class_type("System.Guid"), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [float32_type])))], ["GetUv", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(tuple_type(float32_type, float32_type))]))))], ["GetSpeed", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)]))))]]);
}

export class Api_Fvcom_Batch extends Record {
    constructor(GetNodeDepths, GetElementDepths, GetSpeed, GetTemp, GetSalinity) {
        super();
        this.GetNodeDepths = GetNodeDepths;
        this.GetElementDepths = GetElementDepths;
        this.GetSpeed = GetSpeed;
        this.GetTemp = GetTemp;
        this.GetSalinity = GetSalinity;
    }
}

export function Api_Fvcom_Batch_$reflection() {
    return record_type("Sorcerer.Types.Api.Fvcom.Batch", [], Api_Fvcom_Batch, () => [["GetNodeDepths", lambda_type(class_type("System.Guid"), lambda_type(array_type(int32_type), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(array_type(float32_type))])))], ["GetElementDepths", lambda_type(class_type("System.Guid"), lambda_type(array_type(int32_type), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(array_type(float32_type))])))], ["GetSpeed", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(array_type(int32_type), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(array_type(float32_type))]))))], ["GetTemp", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(array_type(int32_type), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(array_type(float32_type))]))))], ["GetSalinity", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(array_type(int32_type), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(array_type(float32_type))]))))]]);
}

export class Api_Fvcom_TimeSeries extends Record {
    constructor(GetSpeed, GetUv, GetTemp, GetSalinity, GetZeta) {
        super();
        this.GetSpeed = GetSpeed;
        this.GetUv = GetUv;
        this.GetTemp = GetTemp;
        this.GetSalinity = GetSalinity;
        this.GetZeta = GetZeta;
    }
}

export function Api_Fvcom_TimeSeries_$reflection() {
    return record_type("Sorcerer.Types.Api.Fvcom.TimeSeries", [], Api_Fvcom_TimeSeries, () => [["GetSpeed", lambda_type(class_type("System.Guid"), lambda_type(tuple_type(int32_type, int32_type, int32_type), lambda_type(int32_type, lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))], ["GetUv", lambda_type(class_type("System.Guid"), lambda_type(tuple_type(int32_type, int32_type, int32_type), lambda_type(int32_type, lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(tuple_type(float32_type, float32_type))])))))], ["GetTemp", lambda_type(class_type("System.Guid"), lambda_type(tuple_type(int32_type, int32_type, int32_type), lambda_type(int32_type, lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))], ["GetSalinity", lambda_type(class_type("System.Guid"), lambda_type(tuple_type(int32_type, int32_type, int32_type), lambda_type(int32_type, lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))], ["GetZeta", lambda_type(class_type("System.Guid"), lambda_type(tuple_type(int32_type, int32_type, int32_type), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)]))))]]);
}

export const Api_Drifters_routeBuilder = (typeName) => ((methodName) => Api_routeBuilder("drifters", typeName, methodName));

export class Api_Drifters_Archive extends Record {
    constructor(GetNumFrames, GetSimType) {
        super();
        this.GetNumFrames = GetNumFrames;
        this.GetSimType = GetSimType;
    }
}

export function Api_Drifters_Archive_$reflection() {
    return record_type("Sorcerer.Types.Api.Drifters.Archive", [], Api_Drifters_Archive, () => [["GetNumFrames", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [int32_type]))], ["GetSimType", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [Types_DriftersVariant_$reflection()]))]]);
}

export class Api_Drifters_Particles extends Record {
    constructor(GetFrame, GetReleaseSites) {
        super();
        this.GetFrame = GetFrame;
        this.GetReleaseSites = GetReleaseSites;
    }
}

export function Api_Drifters_Particles_$reflection() {
    return record_type("Sorcerer.Types.Api.Drifters.Particles", [], Api_Drifters_Particles, () => [["GetFrame", lambda_type(class_type("System.Guid"), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(ApiParticle_$reflection())])))], ["GetReleaseSites", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(tuple_type(float32_type, float32_type, float32_type))]))]]);
}

export class Api_Drifters_Network extends Record {
    constructor(GetReleaseSites, GetConnectionMatrix) {
        super();
        this.GetReleaseSites = GetReleaseSites;
        this.GetConnectionMatrix = GetConnectionMatrix;
    }
}

export function Api_Drifters_Network_$reflection() {
    return record_type("Sorcerer.Types.Api.Drifters.Network", [], Api_Drifters_Network, () => [["GetReleaseSites", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(anonRecord_type(["coord", tuple_type(float64_type, float64_type)], ["radius", float64_type]))]))], ["GetConnectionMatrix", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(FieldKind_$reflection(), lambda_type(list_type(GroupKind_$reflection()), lambda_type(list_type(ParticleKind_$reflection()), lambda_type(ParticleState_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(array_type(float64_type))])))))))]]);
}

export class Api_Drifters_Sedimentation extends Record {
    constructor(GetField, GetContour, GetSeries) {
        super();
        this.GetField = GetField;
        this.GetContour = GetContour;
        this.GetSeries = GetSeries;
    }
}

export function Api_Drifters_Sedimentation_$reflection() {
    return record_type("Sorcerer.Types.Api.Drifters.Sedimentation", [], Api_Drifters_Sedimentation, () => [["GetField", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(FieldKind_$reflection(), lambda_type(SedimentKind_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))], ["GetContour", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(FieldKind_$reflection(), lambda_type(SedimentKind_$reflection(), lambda_type(float64_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(array_type(tuple_type(float64_type, float64_type)))]))))))], ["GetSeries", lambda_type(class_type("System.Guid"), lambda_type(tuple_type(int32_type, int32_type), lambda_type(FieldKind_$reflection(), lambda_type(SedimentKind_$reflection(), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)]))))))]]);
}

export class Api_Drifters_Field2D extends Record {
    constructor(GetWaterContactField, GetWaterContactSeries, GetField, GetSeries) {
        super();
        this.GetWaterContactField = GetWaterContactField;
        this.GetWaterContactSeries = GetWaterContactSeries;
        this.GetField = GetField;
        this.GetSeries = GetSeries;
    }
}

export function Api_Drifters_Field2D_$reflection() {
    return record_type("Sorcerer.Types.Api.Drifters.Field2D", [], Api_Drifters_Field2D, () => [["GetWaterContactField", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(FieldKind_$reflection(), lambda_type(list_type(GroupKind_$reflection()), lambda_type(list_type(ParticleKind_$reflection()), lambda_type(ParticleState_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))))], ["GetWaterContactSeries", lambda_type(class_type("System.Guid"), lambda_type(tuple_type(int32_type, int32_type), lambda_type(FieldKind_$reflection(), lambda_type(list_type(GroupKind_$reflection()), lambda_type(list_type(ParticleKind_$reflection()), lambda_type(ParticleState_$reflection(), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)]))))))))], ["GetField", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(FieldKind_$reflection(), lambda_type(list_type(GroupKind_$reflection()), lambda_type(list_type(ParticleKind_$reflection()), lambda_type(ParticleState_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))))], ["GetSeries", lambda_type(class_type("System.Guid"), lambda_type(tuple_type(int32_type, int32_type), lambda_type(FieldKind_$reflection(), lambda_type(list_type(GroupKind_$reflection()), lambda_type(list_type(ParticleKind_$reflection()), lambda_type(ParticleState_$reflection(), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)]))))))))]]);
}

export class Api_Drifters_Field3D extends Record {
    constructor(GetField, GetSeries) {
        super();
        this.GetField = GetField;
        this.GetSeries = GetSeries;
    }
}

export function Api_Drifters_Field3D_$reflection() {
    return record_type("Sorcerer.Types.Api.Drifters.Field3D", [], Api_Drifters_Field3D, () => [["GetField", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(FieldKind_$reflection(), lambda_type(list_type(GroupKind_$reflection()), lambda_type(list_type(ParticleKind_$reflection()), lambda_type(ParticleState_$reflection(), lambda_type(list_type(int32_type), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)]))))))))], ["GetSeries", lambda_type(class_type("System.Guid"), lambda_type(tuple_type(int32_type, int32_type), lambda_type(FieldKind_$reflection(), lambda_type(list_type(GroupKind_$reflection()), lambda_type(list_type(ParticleKind_$reflection()), lambda_type(ParticleState_$reflection(), lambda_type(list_type(int32_type), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))))))]]);
}

export class Api_Drifters_FieldMetaData extends Record {
    constructor(GetAvailableFields, GetAvailableLayers, GetAvailableGroups, GetAvailableParticles) {
        super();
        this.GetAvailableFields = GetAvailableFields;
        this.GetAvailableLayers = GetAvailableLayers;
        this.GetAvailableGroups = GetAvailableGroups;
        this.GetAvailableParticles = GetAvailableParticles;
    }
}

export function Api_Drifters_FieldMetaData_$reflection() {
    return record_type("Sorcerer.Types.Api.Drifters.FieldMetaData", [], Api_Drifters_FieldMetaData, () => [["GetAvailableFields", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [list_type(FieldKind_$reflection())]))], ["GetAvailableLayers", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [list_type(LayerType_$reflection())]))], ["GetAvailableGroups", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [list_type(GroupType_$reflection())]))], ["GetAvailableParticles", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [list_type(ParticleType_$reflection())]))]]);
}

export const Api_Atmo_routeBuilder = (typeName) => ((methodName) => Api_routeBuilder("atmo", typeName, methodName));

export class Api_Atmo_Wind extends Record {
    constructor(WindTile, GetBarbSigns) {
        super();
        this.WindTile = WindTile;
        this.GetBarbSigns = GetBarbSigns;
    }
}

export function Api_Atmo_Wind_$reflection() {
    return record_type("Sorcerer.Types.Api.Atmo.Wind", [], Api_Atmo_Wind, () => [["WindTile", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(int32_type, lambda_type(int32_type, lambda_type(int32_type, lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(option_type(V2$1_$reflection(float32_type)))])))))))], ["GetBarbSigns", lambda_type(unit_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(Arrow$1_$reflection(float64_type))]))]]);
}

export const Api_Proximity_routeBuilder = (typeName) => ((methodName) => Api_routeBuilder("prox", typeName, methodName));

export class Api_Proximity_Prox extends Record {
    constructor(GetNearestElements, GetNearestNodes) {
        super();
        this.GetNearestElements = GetNearestElements;
        this.GetNearestNodes = GetNearestNodes;
    }
}

export function Api_Proximity_Prox_$reflection() {
    return record_type("Sorcerer.Types.Api.Proximity.Prox", [], Api_Proximity_Prox, () => [["GetNearestElements", lambda_type(class_type("System.Guid"), lambda_type(array_type(tuple_type(float64_type, float64_type)), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(option_type(int32_type))])))], ["GetNearestNodes", lambda_type(class_type("System.Guid"), lambda_type(array_type(tuple_type(float64_type, float64_type)), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(option_type(int32_type))])))]]);
}

export const Api_Stats_routeBuilder = (typeName) => ((methodName) => Api_routeBuilder("stats", typeName, methodName));

export class Api_Stats_FvStatsInfo extends Record {
    constructor(GetAvailableStats, GetSectors, GetNumLayers) {
        super();
        this.GetAvailableStats = GetAvailableStats;
        this.GetSectors = GetSectors;
        this.GetNumLayers = GetNumLayers;
    }
}

export function Api_Stats_FvStatsInfo_$reflection() {
    return record_type("Sorcerer.Types.Api.Stats.FvStatsInfo", [], Api_Stats_FvStatsInfo, () => [["GetAvailableStats", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(StatProp_$reflection())]))], ["GetSectors", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)]))], ["GetNumLayers", lambda_type(class_type("System.Guid"), lambda_type(StatProp_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [int32_type])))]]);
}

export class Api_Stats_FvStatsByLayer extends Record {
    constructor(GetSalinity, GetTemperature, GetUV, GetSpeed) {
        super();
        this.GetSalinity = GetSalinity;
        this.GetTemperature = GetTemperature;
        this.GetUV = GetUV;
        this.GetSpeed = GetSpeed;
    }
}

export function Api_Stats_FvStatsByLayer_$reflection() {
    return record_type("Sorcerer.Types.Api.Stats.FvStatsByLayer", [], Api_Stats_FvStatsByLayer, () => [["GetSalinity", lambda_type(class_type("System.Guid"), lambda_type(Period_$reflection(), lambda_type(StatMetric_$reflection(), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))], ["GetTemperature", lambda_type(class_type("System.Guid"), lambda_type(Period_$reflection(), lambda_type(StatMetric_$reflection(), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))], ["GetUV", lambda_type(class_type("System.Guid"), lambda_type(Period_$reflection(), lambda_type(StatMetric_$reflection(), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(tuple_type(float32_type, float32_type))])))))], ["GetSpeed", lambda_type(class_type("System.Guid"), lambda_type(Period_$reflection(), lambda_type(StatMetric_$reflection(), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))]]);
}

export class Api_Stats_FvStatsByIndex extends Record {
    constructor(GetSalinity, GetTemperature, GetUV, GetSpeed, GetWaterTransport) {
        super();
        this.GetSalinity = GetSalinity;
        this.GetTemperature = GetTemperature;
        this.GetUV = GetUV;
        this.GetSpeed = GetSpeed;
        this.GetWaterTransport = GetWaterTransport;
    }
}

export function Api_Stats_FvStatsByIndex_$reflection() {
    return record_type("Sorcerer.Types.Api.Stats.FvStatsByIndex", [], Api_Stats_FvStatsByIndex, () => [["GetSalinity", lambda_type(class_type("System.Guid"), lambda_type(Period_$reflection(), lambda_type(StatMetric_$reflection(), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))], ["GetTemperature", lambda_type(class_type("System.Guid"), lambda_type(Period_$reflection(), lambda_type(StatMetric_$reflection(), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))], ["GetUV", lambda_type(class_type("System.Guid"), lambda_type(Period_$reflection(), lambda_type(StatMetric_$reflection(), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(tuple_type(float32_type, float32_type))])))))], ["GetSpeed", lambda_type(class_type("System.Guid"), lambda_type(Period_$reflection(), lambda_type(StatMetric_$reflection(), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))], ["GetWaterTransport", lambda_type(class_type("System.Guid"), lambda_type(Period_$reflection(), lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(array_type(float32_type))]))))]]);
}

export class Api_Stats_FvStatsSeries extends Record {
    constructor(GetSalinity, GetTemperature, GetSpeed) {
        super();
        this.GetSalinity = GetSalinity;
        this.GetTemperature = GetTemperature;
        this.GetSpeed = GetSpeed;
    }
}

export function Api_Stats_FvStatsSeries_$reflection() {
    return record_type("Sorcerer.Types.Api.Stats.FvStatsSeries", [], Api_Stats_FvStatsSeries, () => [["GetSalinity", lambda_type(class_type("System.Guid"), lambda_type(StatMetric_$reflection(), lambda_type(int32_type, lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))], ["GetTemperature", lambda_type(class_type("System.Guid"), lambda_type(StatMetric_$reflection(), lambda_type(int32_type, lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))], ["GetSpeed", lambda_type(class_type("System.Guid"), lambda_type(StatMetric_$reflection(), lambda_type(int32_type, lambda_type(int32_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))]]);
}

export const Api_Crop_routeBuilder = (typeName) => ((methodName) => Api_routeBuilder("crop", typeName, methodName));

export class Api_Crop_Fvcom extends Record {
    constructor(CropTemp, CropSalinity, CropSpeed, CropUV) {
        super();
        this.CropTemp = CropTemp;
        this.CropSalinity = CropSalinity;
        this.CropSpeed = CropSpeed;
        this.CropUV = CropUV;
    }
}

export function Api_Crop_Fvcom_$reflection() {
    return record_type("Sorcerer.Types.Api.Crop.Fvcom", [], Api_Crop_Fvcom, () => [["CropTemp", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(tuple_type(float64_type, float64_type), lambda_type(tuple_type(float64_type, float64_type), lambda_type(tuple_type(int32_type, int32_type), lambda_type(float64_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))))], ["CropSalinity", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(tuple_type(float64_type, float64_type), lambda_type(tuple_type(float64_type, float64_type), lambda_type(tuple_type(int32_type, int32_type), lambda_type(float64_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))))], ["CropSpeed", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(tuple_type(float64_type, float64_type), lambda_type(tuple_type(float64_type, float64_type), lambda_type(tuple_type(int32_type, int32_type), lambda_type(float64_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)])))))))], ["CropUV", lambda_type(class_type("System.Guid"), lambda_type(int32_type, lambda_type(tuple_type(float64_type, float64_type), lambda_type(tuple_type(float64_type, float64_type), lambda_type(tuple_type(int32_type, int32_type), lambda_type(float64_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(tuple_type(float32_type, float32_type))])))))))]]);
}

export class Api_Crop_Stats extends Record {
    constructor(CropTemp, CropSalinity, CropSpeed, CropUV) {
        super();
        this.CropTemp = CropTemp;
        this.CropSalinity = CropSalinity;
        this.CropSpeed = CropSpeed;
        this.CropUV = CropUV;
    }
}

export function Api_Crop_Stats_$reflection() {
    return record_type("Sorcerer.Types.Api.Crop.Stats", [], Api_Crop_Stats, () => [["CropTemp", lambda_type(class_type("System.Guid"), lambda_type(Period_$reflection(), lambda_type(StatMetric_$reflection(), lambda_type(tuple_type(float64_type, float64_type), lambda_type(tuple_type(float64_type, float64_type), lambda_type(tuple_type(int32_type, int32_type), lambda_type(float64_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)]))))))))], ["CropSalinity", lambda_type(class_type("System.Guid"), lambda_type(Period_$reflection(), lambda_type(StatMetric_$reflection(), lambda_type(tuple_type(float64_type, float64_type), lambda_type(tuple_type(float64_type, float64_type), lambda_type(tuple_type(int32_type, int32_type), lambda_type(float64_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)]))))))))], ["CropSpeed", lambda_type(class_type("System.Guid"), lambda_type(Period_$reflection(), lambda_type(StatMetric_$reflection(), lambda_type(tuple_type(float64_type, float64_type), lambda_type(tuple_type(float64_type, float64_type), lambda_type(tuple_type(int32_type, int32_type), lambda_type(float64_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(float32_type)]))))))))], ["CropUV", lambda_type(class_type("System.Guid"), lambda_type(Period_$reflection(), lambda_type(StatMetric_$reflection(), lambda_type(tuple_type(float64_type, float64_type), lambda_type(tuple_type(float64_type, float64_type), lambda_type(tuple_type(int32_type, int32_type), lambda_type(float64_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(tuple_type(float32_type, float32_type))]))))))))]]);
}

