import { Record, toString, Union } from "../../fable_modules/fable-library.4.5.0/Types.js";
import { tuple_type, float64_type, record_type, int32_type, option_type, array_type, string_type, class_type, bool_type, union_type } from "../../fable_modules/fable-library.4.5.0/Reflection.js";
import { split } from "../../fable_modules/fable-library.4.5.0/String.js";
import { PostdriftInput_$reflection, DriftersInput_$reflection, SimType_$reflection } from "../Drifters/ApiTypes.js";
import { maxValue, minValue } from "../../fable_modules/fable-library.4.5.0/Date.js";

export class DriftersAction extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["Submit"];
    }
    toString() {
        return "submit";
    }
}

export function DriftersAction_$reflection() {
    return union_type("Hipster.Interfaces.Actors.DriftersAction", [], DriftersAction, () => [[]]);
}

export class DriftersPolicy extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SubmitTransport", "SubmitSedimentation"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? (`run:sedimentation;${x.fields[0]}`) : (`run:transport;${x.fields[0]}`);
    }
}

export function DriftersPolicy_$reflection() {
    return union_type("Hipster.Interfaces.Actors.DriftersPolicy", [], DriftersPolicy, () => [[["Item", bool_type]], [["Item", bool_type]]]);
}

export function DriftersPolicy__IsAllowed(x) {
    if (x.tag === 0) {
        return x.fields[0];
    }
    else {
        return x.fields[0];
    }
}

export function DriftersPolicyModule_toString(x) {
    return toString(x);
}

export function DriftersPolicyModule_isAllowed(x) {
    return DriftersPolicy__IsAllowed(x);
}

export function DriftersPolicyModule_fromString(s) {
    let patternInput;
    const y = split(s, [";"], void 0, 0);
    patternInput = [y[0], y[1] === "True"];
    const v = patternInput[1];
    const cap = patternInput[0];
    switch (cap) {
        case "run:transport":
            return new DriftersPolicy(0, [v]);
        case "run:sedimentation":
            return new DriftersPolicy(1, [v]);
        default:
            return new DriftersPolicy(0, [false]);
    }
}

export function DriftersPolicyModule_toPolicy(m, a, v) {
    if (m.tag === 3) {
        return new DriftersPolicy(1, [v]);
    }
    else {
        return new DriftersPolicy(0, [v]);
    }
}

export class DriftersJob extends Record {
    constructor(aid, name, model, input, groups, partition, dependency) {
        super();
        this.aid = aid;
        this.name = name;
        this.model = model;
        this.input = input;
        this.groups = groups;
        this.partition = partition;
        this.dependency = dependency;
    }
}

export function DriftersJob_$reflection() {
    return record_type("Hipster.Interfaces.Actors.DriftersJob", [], DriftersJob, () => [["aid", class_type("System.Guid")], ["name", string_type], ["model", SimType_$reflection()], ["input", DriftersInput_$reflection()], ["groups", option_type(array_type(string_type))], ["partition", option_type(string_type)], ["dependency", option_type(int32_type)]]);
}

export class PostdriftJob extends Record {
    constructor(aid, name, model, input, groups, partition, dependency) {
        super();
        this.aid = aid;
        this.name = name;
        this.model = model;
        this.input = input;
        this.groups = groups;
        this.partition = partition;
        this.dependency = dependency;
    }
}

export function PostdriftJob_$reflection() {
    return record_type("Hipster.Interfaces.Actors.PostdriftJob", [], PostdriftJob, () => [["aid", class_type("System.Guid")], ["name", string_type], ["model", SimType_$reflection()], ["input", PostdriftInput_$reflection()], ["groups", option_type(array_type(string_type))], ["partition", option_type(string_type)], ["dependency", option_type(int32_type)]]);
}

export class PlumeJob extends Record {
    constructor(name, fvcom, pos, temp, salt, depth, theta, transport, radius, start, stop, timeIdx) {
        super();
        this.name = name;
        this.fvcom = fvcom;
        this.pos = pos;
        this.temp = temp;
        this.salt = salt;
        this.depth = depth;
        this.theta = theta;
        this.transport = transport;
        this.radius = radius;
        this.start = start;
        this.stop = stop;
        this.timeIdx = (timeIdx | 0);
    }
}

export function PlumeJob_$reflection() {
    return record_type("Hipster.Interfaces.Actors.PlumeJob", [], PlumeJob, () => [["name", string_type], ["fvcom", class_type("System.Guid")], ["pos", tuple_type(float64_type, float64_type)], ["temp", float64_type], ["salt", float64_type], ["depth", float64_type], ["theta", float64_type], ["transport", float64_type], ["radius", float64_type], ["start", class_type("System.DateTime")], ["stop", class_type("System.DateTime")], ["timeIdx", int32_type]]);
}

export function PlumeJob_get_empty() {
    return new PlumeJob("", "00000000-0000-0000-0000-000000000000", [0, 0], 0, 0, 0, 0, 0, 0, minValue(), maxValue(), 0);
}

