import { keyValueList } from "../fable-library.4.5.0/MapUtil.js";
import Tile from "ol/source/Tile";
import VectorTile from "ol/source/VectorTile";
import XYZ from "ol/source/XYZ";
import OSM from "ol/source/OSM";
import WMTS from "ol/source/WMTS";
import TileWMS from "ol/source/TileWMS";
import Vector from "ol/source/Vector";
import Cluster from "ol/source/Cluster";
import Image from "ol/source/Image";
import ImageWMS from "ol/source/ImageWMS";
import ImageCanvas from "ol/source/ImageCanvas";
import { class_type } from "../fable-library.4.5.0/Reflection.js";

export function Source_Tile_tileSource(options) {
    const opts = keyValueList(options, 1);
    return new TileSource(opts);
}

export function Source_Tile_vectorTile(options) {
    const opts = keyValueList(options, 1);
    return new VectorTile(opts);
}

export function Source_xyz(options) {
    const opts = keyValueList(options, 1);
    return new XYZ(opts);
}

export function Source_osm(options) {
    const opts = keyValueList(options, 1);
    return new OSM(opts);
}

export function Source_wmts(options) {
    const opts = keyValueList(options, 1);
    return new WMTS(opts);
}

export function Source_tileWMS(options) {
    const opts = keyValueList(options, 1);
    return new TileWMS(opts);
}

export function Source_vectorSource(options) {
    const opts = keyValueList(options, 1);
    return new Vector(opts);
}

export function Source_clusterSource(options) {
    const opts = keyValueList(options, 1);
    return new Cluster(opts);
}

export function Source_imageSource(options) {
    const opts = keyValueList(options, 1);
    return new ImageSource(opts);
}

export function Source_imageWMS(options) {
    const opts = keyValueList(options, 1);
    return new ImageWMS(opts);
}

export function Source_imageCanvasSource(options) {
    const opts = keyValueList(options, 1);
    return new ImageCanvasSource(opts);
}

export class source {
    constructor() {
    }
}

export function source_$reflection() {
    return class_type("Fable.OpenLayers.source", void 0, source);
}

export class cluster {
    constructor() {
    }
}

export function cluster_$reflection() {
    return class_type("Fable.OpenLayers.cluster", void 0, cluster);
}

