import { Attribute, FSharpRef, Union } from "../fable-library.4.5.0/Types.js";
import { union_type, lambda_type, class_type, unit_type } from "../fable-library.4.5.0/Reflection.js";
import { equalsWith } from "../fable-library.4.5.0/Array.js";
import { clear, disposeSafe, getEnumerator, equals as equals_3 } from "../fable-library.4.5.0/Util.js";
import { iterate } from "../fable-library.4.5.0/Seq.js";
import { toArray } from "../fable-library.4.5.0/Option.js";
import { Css_scope } from "./Parser.fs.js";
import { noChange } from "lit";
import { AsyncDirective } from "lit/async-directive.js";
import { directive } from "lit/directive.js";

export function HookUtil_generateShortUid() {
            const firstPart = (Math.random() * 46656) | 0;
        const secondPart = (Math.random() * 46656) | 0;
        return "_" + firstPart.toString(36) + secondPart.toString(36);
        ;
}

export const HookUtil_cssClasses = new WeakMap();

export function HookUtil_createDisposable(f) {
    return {
        Dispose() {
            f();
        },
    };
}

export const HookUtil_emptyDisposable = HookUtil_createDisposable(() => {
});

export function HookUtil_delay(ms, f) {
    setTimeout(f, ms);
}

export function HookUtil_runAsync(f) {
    HookUtil_delay(0, f);
}

export class HookUtil_Effect extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["OnConnected", "OnRender"];
    }
}

export function HookUtil_Effect_$reflection() {
    return union_type("Lit.HookUtil.Effect", [], HookUtil_Effect, () => [[["Item", lambda_type(unit_type, class_type("System.IDisposable"))]], [["Item", lambda_type(unit_type, unit_type)]]]);
}

export class TransitionState extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["HasLeft", "AboutToEnter", "Entering", "HasEntered", "Leaving"];
    }
}

export function TransitionState_$reflection() {
    return union_type("Lit.TransitionState", [], TransitionState, () => [[], [], [], [], []]);
}

export class HookContext {
    constructor(host) {
        this["host@80"] = host;
        this._firstRun = true;
        this._rendering = false;
        this._args = [];
        this._stateIndex = 0;
        this._effectIndex = 0;
        this._states = [];
        this._effects = [];
        this._disposables = [];
    }
    get host() {
        const _ = this;
        return _["host@80"];
    }
    fail() {
        throw new Error("Hooks must be called consistently for each render call");
    }
    requestUpdate() {
        const _ = this;
        _["host@80"].requestUpdate();
    }
    renderWith(args) {
        const this$ = this;
        if (this$._firstRun ? true : !equalsWith(equals_3, args, this$._args)) {
            this$._args = args;
            return this$.render();
        }
        else {
            return void 0;
        }
    }
    render() {
        const this$ = this;
        this$._stateIndex = 0;
        this$._effectIndex = 0;
        this$._rendering = true;
        const res = this$["host@80"].renderFn.apply(this$["host@80"], this$._args);
        if (!this$._firstRun && ((this$._stateIndex !== this$._states.length) ? true : (this$._effectIndex !== this$._effects.length))) {
            this$.fail();
        }
        this$._rendering = false;
        if (this$["host@80"].isConnected) {
            this$.runEffects(this$._firstRun, true);
        }
        this$._firstRun = false;
        return res;
    }
    checkRendering() {
        const this$ = this;
        if (!this$._rendering) {
            this$.fail();
        }
    }
    runEffects(onConnected, onRender) {
        const _ = this;
        HookUtil_runAsync(() => {
            iterate((_arg) => {
                if (_arg.tag === 0) {
                    if (onConnected) {
                        void (_._disposables.push(_arg.fields[0]()));
                    }
                }
                else if (onRender) {
                    _arg.fields[0]();
                }
            }, _._effects);
        });
    }
    setState(index, newValue, equals) {
        let oldValue, newValue_1;
        const _ = this;
        if (!((oldValue = _._states[index], (newValue_1 = newValue, (equals == null) ? equals_3(oldValue, newValue_1) : equals(oldValue, newValue_1))))) {
            _._states[index] = newValue;
            if (!_._rendering) {
                _["host@80"].requestUpdate();
            }
            else {
                HookUtil_runAsync(() => {
                    _["host@80"].requestUpdate();
                });
            }
        }
    }
    getState() {
        const this$ = this;
        if (this$._stateIndex >= this$._states.length) {
            this$.fail();
        }
        const idx = this$._stateIndex | 0;
        this$._stateIndex = ((idx + 1) | 0);
        return [idx, this$._states[idx]];
    }
    addState(state) {
        const _ = this;
        void (_._states.push(state));
        return [_._states.length - 1, state];
    }
    disconnect() {
        const _ = this;
        let enumerator = getEnumerator(_._disposables);
        try {
            while (enumerator["System.Collections.IEnumerator.MoveNext"]()) {
                disposeSafe(enumerator["System.Collections.Generic.IEnumerator`1.get_Current"]());
            }
        }
        finally {
            disposeSafe(enumerator);
        }
        clear(_._disposables);
    }
    useState(init) {
        const this$ = this;
        this$.checkRendering();
        let patternInput;
        if (this$._firstRun) {
            const state = init();
            patternInput = this$.addState(state);
        }
        else {
            patternInput = this$.getState();
        }
        return [patternInput[1], (v) => {
            this$.setState(patternInput[0], v);
        }];
    }
    useRef(init) {
        let state;
        const this$ = this;
        this$.checkRendering();
        return this$._firstRun ? ((state = (new FSharpRef(init())), this$.addState(state)))[1] : this$.getState()[1];
    }
    setEffect(effect) {
        const this$ = this;
        this$.checkRendering();
        if (this$._firstRun) {
            void (this$._effects.push(effect));
        }
        else {
            if (this$._effectIndex >= this$._effects.length) {
                this$.fail();
            }
            const idx = this$._effectIndex | 0;
            this$._effectIndex = ((idx + 1) | 0);
            this$._effects[idx] = effect;
        }
    }
    useEffect(effect) {
        const this$ = this;
        this$.setEffect(new HookUtil_Effect(1, [effect]));
    }
    useEffectOnce(effect) {
        const this$ = this;
        this$.setEffect(new HookUtil_Effect(0, [effect]));
    }
}

export function HookContext_$reflection() {
    return class_type("Lit.HookContext", void 0, HookContext);
}

export function HookContext_$ctor_Z3A7AB6DA(host) {
    return new HookContext(host);
}

/**
 * Indicates whether the transition has already left.
 * Note the transition doesn't remove/hide the element by itself,
 * this has to be done in the `onComplete` event.
 */
export function Lit_Transition__Transition_get_hasLeft(this$) {
    if (this$.state.tag === 0) {
        return true;
    }
    else {
        return false;
    }
}

/**
 * Indicates whether the transition is currently entering or leaving.
 * Useful to disable buttons, for example.
 */
export function Lit_Transition__Transition_get_isRunning(this$) {
    const matchValue = this$.state;
    switch (matchValue.tag) {
        case 3:
        case 0:
            return false;
        default:
            return true;
    }
}

export function Lit_HookContext__HookContext_useState_1505(ctx, v) {
    return ctx.useState(() => v);
}

export function Lit_HookContext__HookContext_useRef_1505(ctx, v) {
    return ctx.useRef(() => v);
}

export function Lit_HookContext__HookContext_useRef(ctx) {
    return ctx.useRef(() => void 0);
}

export function Lit_HookContext__HookContext_useMemo_FCFD9EF(ctx, init) {
    return ctx.useRef(init).contents;
}

export function Lit_HookContext__HookContext_useEffectOnce_3A5B6456(ctx, effect) {
    ctx.useEffectOnce(() => {
        effect();
        return HookUtil_emptyDisposable;
    });
}

export function Lit_HookContext__HookContext_useEffectOnChange_31A5188A(ctx, value, effect) {
    Lit_HookContext__HookContext_useEffectOnChange_C240689(ctx, value, (v) => {
        effect(v);
        return HookUtil_emptyDisposable;
    });
}

export function Lit_HookContext__HookContext_useEffectOnChange_C240689(ctx, value, effect) {
    const prev = Lit_HookContext__HookContext_useRef(ctx);
    ctx.useEffect(() => {
        const matchValue = prev.contents;
        if (matchValue != null) {
            const disp = matchValue[1];
            if (!equals_3(matchValue[0], value)) {
                disposeSafe(disp);
                prev.contents = [value, effect(value)];
            }
        }
        else {
            prev.contents = [value, effect(value)];
        }
    });
}

export function Lit_HookContext__HookContext_useTransition_66B2ACBC(ctx, ms, onEntered, onLeft) {
    const patternInput = Lit_HookContext__HookContext_useState_1505(ctx, new TransitionState(1, []));
    const state = patternInput[0];
    const setState = patternInput[1];
    const trigger = (isIn) => {
        const patternInput_1 = isIn ? [new TransitionState(2, []), new TransitionState(3, [])] : [new TransitionState(4, []), new TransitionState(0, [])];
        HookUtil_delay(ms, () => {
            setState(patternInput_1[1]);
            iterate((f_1) => {
                f_1();
            }, toArray(isIn ? onEntered : onLeft));
        });
        setState(patternInput_1[0]);
    };
    Lit_HookContext__HookContext_useEffectOnChange_31A5188A(ctx, state, (_arg) => {
        if (_arg.tag === 1) {
            trigger(true);
        }
    });
    return new (class {
        get state() {
            return state;
        }
        get className() {
            return (state.tag === 1) ? "transition-enter" : ((state.tag === 2) ? "" : ((state.tag === 3) ? "" : ((state.tag === 4) ? "transition-leave" : "transition-enter")));
        }
        triggerEnter() {
            setState(new TransitionState(1, []));
        }
        triggerLeave() {
            trigger(false);
        }
    }
    )();
}

export function Lit_HookContext__HookContext_remove_css(ctx) {
    const proto = Object.getPrototypeOf(ctx.host);
    const cssClass = HookUtil_cssClasses.get(proto);
    if (!(cssClass == null)) {
        HookUtil_cssClasses.delete(proto);
        const doc = document;
        const headEl = doc.querySelector("head");
        headEl.removeChild(doc.getElementById(cssClass));
    }
}

export function Lit_HookContext__HookContext_use_scoped_css_Z721C83C5(ctx, rules) {
    let newChild, data;
    const proto = Object.getPrototypeOf(ctx.host);
    const className = HookUtil_cssClasses.get(proto);
    if (className == null) {
        const className_1 = HookUtil_generateShortUid();
        const doc = document;
        const styleEl = doc.createElement("style");
        styleEl.setAttribute("id", className_1);
        (newChild = ((data = Css_scope(className_1, rules), doc.createTextNode(data))), styleEl.appendChild(newChild));
        doc.querySelector("head").appendChild(styleEl);
        HookUtil_cssClasses.set(proto, className_1);
        return className_1;
    }
    else {
        return className;
    }
}

export class HookDirective extends AsyncDirective {
    constructor() {
        super();
        this._hooks = (new HookContext(this));
    }
    requestUpdate() {
        const this$ = this;
        this$.setValue(this$._hooks.render());
    }
    render(...args) {
        const _ = this;
        const matchValue = _._hooks.renderWith(args);
        return (matchValue == null) ? noChange : matchValue;
    }
    disconnected() {
        const _ = this;
        _._hooks.disconnect();
    }
    reconnected() {
        const _ = this;
        _._hooks.runEffects(true, false);
    }
    get hooks() {
        const _ = this;
        return _._hooks;
    }
}

export function HookDirective_$reflection() {
    return class_type("Lit.HookDirective", void 0, HookDirective, class_type("Lit.Types.AsyncDirective", void 0, AsyncDirective));
}

export function HookDirective_$ctor() {
    return new HookDirective();
}

export class HookComponentAttribute extends Attribute {
    constructor() {
        super();
    }
    Decorate(renderFn) {
        return directive(class extends HookDirective {
            constructor() { super() }
            get renderFn() { return renderFn }
        });
    }
}

export function HookComponentAttribute_$reflection() {
    return class_type("Lit.HookComponentAttribute", void 0, HookComponentAttribute, class_type("Fable.Core.JS.DecoratorAttribute"));
}

export function HookComponentAttribute_$ctor() {
    return new HookComponentAttribute();
}

export class Hook {
    constructor() {
    }
}

export function Hook_$reflection() {
    return class_type("Lit.Hook", void 0, Hook);
}

export function Hook_$ctor() {
    return new Hook();
}

/**
 * Use `getContext()`
 */
export function Hook_getContext_343DAFF1(this$) {
    if ((this$ == null) ? true : !(this$.hooks instanceof HookContext)) {
        throw new Error("Cannot access hook context, make sure the hook is called on top of a HookComponent function");
    }
    return this$.hooks;
}

export function Hook_createDisposable_3A5B6456(f) {
    return HookUtil_createDisposable(f);
}

export function Hook_get_emptyDisposable() {
    return HookUtil_emptyDisposable;
}

export function Hook_useHmr_ZF11B4D8(token, this$) {
}

