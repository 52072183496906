import proj4 from "proj4";
import { register } from "ol/proj/proj4";
import { get as get$ } from "ol/proj";
import { Record, toString, Union } from "./fable_modules/fable-library.4.5.0/Types.js";
import { enum_type, option_type, string_type, tuple_type, class_type, list_type, bool_type, record_type, float64_type, int32_type, array_type, float32_type, union_type } from "./fable_modules/fable-library.4.5.0/Reflection.js";
import { Arrow$1_$reflection, PlainGrid_get_empty, PlainGrid_$reflection, StatMetric_$reflection, Period_$reflection } from "./Interfaces/Sorcerer/ApiTypes.js";
import { Source_osm, Source_xyz, Source_imageWMS } from "./fable_modules/Fable.OpenLayers.2.17.5/Source.fs.js";
import { SimType__ToLabel, SimType_$reflection, SaveFrequency__ToFloat, TemporalSmoothing, FieldKind, ParticleType, ParticleKind, GroupType, GroupKind, LayerType, DepthKind, ParticleKind_$reflection, GroupKind_$reflection, ParticleType_$reflection, GroupType_$reflection, LayerType_$reflection, FieldKind_$reflection } from "./Interfaces/Drifters/ApiTypes.js";
import { singleton, empty } from "./fable_modules/fable-library.4.5.0/List.js";
import { ofList } from "./fable_modules/fable-library.4.5.0/Map.js";
import { compare, comparePrimitives } from "./fable_modules/fable-library.4.5.0/Util.js";
import { max, min } from "./fable_modules/fable-library.4.5.0/Array.js";
import { op_Subtraction, now, minValue } from "./fable_modules/fable-library.4.5.0/Date.js";
import { Types_DriftersFormat, Types_DriftersVariant, Types_ArchiveProps, Types_ArchiveProps_get_empty, Types_DriftersFormat_$reflection, Types_DriftersVariant_$reflection, Types_ArchiveProps_$reflection } from "./Interfaces/Archmeister/ApiTypes.js";
import { fromDays } from "./fable_modules/fable-library.4.5.0/TimeSpan.js";
import { defaultArg } from "./fable_modules/fable-library.4.5.0/Option.js";

proj4.defs("EPSG:25832", "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");

proj4.defs("EPSG:25833", "+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");

register(proj4);

export const wgs84 = get$("EPSG:4326");

export const epsg3857 = get$("EPSG:3857");

export const epsg25833 = get$("EPSG:25833");

export const epsg25832 = get$("EPSG:25832");

export const mapAlpha0 = 0.35;

export const mapAlpha1 = 0.95;

export class Tab extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Select", "Map", "Wireframe"];
    }
}

export function Tab_$reflection() {
    return union_type("Atlantis.Types.Tab", [], Tab, () => [[], [], []]);
}

export class SimMode extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Placing", "Viewing"];
    }
}

export function SimMode_$reflection() {
    return union_type("Atlantis.Types.SimMode", [], SimMode, () => [[], []]);
}

export class Mode extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Ocean", "Stats", "Simulation", "Moot"];
    }
}

export function Mode_$reflection() {
    return union_type("Atlantis.Types.Mode", [], Mode, () => [[], [["Item1", Period_$reflection()], ["Item2", StatMetric_$reflection()]], [["Item", SimMode_$reflection()]], []]);
}

export function Mode__get_string(this$) {
    switch (this$.tag) {
        case 1:
            return "statistics";
        case 2:
            return "simulation";
        case 3:
            return "moot";
        default:
            return "ocean";
    }
}

export function Mode__get_label(this$) {
    switch (this$.tag) {
        case 1:
            return "Statistics";
        case 2:
            return "Simulation";
        case 3:
            return "Moot";
        default:
            return "Ocean";
    }
}

export class Modules extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Ocean", "Particles", "Analytics"];
    }
}

export function Modules_$reflection() {
    return union_type("Atlantis.Types.Modules", [], Modules, () => [[], [], []]);
}

export class NorgesKart extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["BackgroundNorway", "SeaRaster", "GEBCO", "Sentinel2"];
    }
    toString() {
        const this$ = this;
        return (this$.tag === 0) ? "topo" : ((this$.tag === 2) ? "gebco" : ((this$.tag === 3) ? "2023" : "hoved"));
    }
}

export function NorgesKart_$reflection() {
    return union_type("Atlantis.Types.NorgesKart", [], NorgesKart, () => [[], [], [], []]);
}

export function NorgesKart_get_epsgId() {
    return (_arg) => ((_arg.tag === 2) ? "25833" : "3857");
}

export function NorgesKart__url(x) {
    const epsg = NorgesKart_get_epsgId()(x);
    switch (x.tag) {
        case 3:
            return "https://openwms.statkart.no/skwms1/wms.sentinel2";
        case 1:
            return "https://wms.geonorge.no/skwms1/wms.sjokartraster2";
        case 2:
            return "https://opencache.statkart.no/gatekeeper/gk/gk.open_wmts" + (`$${(`Request=GetTile&Service=WMTS&layer=${x}&style=default&Version=1.0.0`) + "&TileCol={x}&TileRow={y}"}&Format=image/png&tileMatrixSet=EPSG:${epsg}&TileMatrix=EPSG:${epsg}:{z}`);
        default:
            return "https://cache.kartverket.no/v1/wmts/1.0.0/topo/default/webmercator/{z}/{y}/{x}.png";
    }
}

export function NorgesKart__source(x) {
    const proj = (x.tag === 2) ? epsg25833 : ((x.tag === 3) ? epsg25833 : ((x.tag === 1) ? epsg3857 : epsg3857));
    switch (x.tag) {
        case 3:
        case 1:
            return Source_imageWMS([["url", NorgesKart__url(x)], ["projection", proj], ["ratio", 1], ["serverType", "geoserver"], ["params", {
                layers: toString(x),
            }]]);
        default:
            return Source_xyz([["projection", proj], ["url", NorgesKart__url(x)]]);
    }
}

export class MapKind extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["OSM", "NorgesKart"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? toString(x.fields[0]) : "OpenStreetmap";
    }
}

export function MapKind_$reflection() {
    return union_type("Atlantis.Types.MapKind", [], MapKind, () => [[], [["Item", NorgesKart_$reflection()]]]);
}

export function MapKind__source(x) {
    if (x.tag === 1) {
        return NorgesKart__source(x.fields[0]);
    }
    else {
        return Source_osm([]);
    }
}

export function MapKind__minZoom(x) {
    if (x.tag === 1) {
        if (x.fields[0].tag === 1) {
            return 10;
        }
        else {
            return 5;
        }
    }
    else {
        return 0;
    }
}

export class MapData extends Record {
    constructor(Grid, Props, Zoom, Center) {
        super();
        this.Grid = Grid;
        this.Props = Props;
        this.Zoom = (Zoom | 0);
        this.Center = Center;
    }
}

export function MapData_$reflection() {
    return record_type("Atlantis.Types.MapData", [], MapData, () => [["Grid", PlainGrid_$reflection()], ["Props", array_type(float32_type)], ["Zoom", int32_type], ["Center", array_type(float64_type)]]);
}

export function MapData_get_empty() {
    return new MapData(PlainGrid_get_empty(), new Float32Array(0), 9, new Float64Array([14.39, 67.9]));
}

export class ParticleFilter extends Record {
    constructor(fetched, availableFieldTypes, availableDepthLayers, availableGroupTypes, availableParticleTypes, showDepthLayer, showGroupKind, showParticleKind) {
        super();
        this.fetched = fetched;
        this.availableFieldTypes = availableFieldTypes;
        this.availableDepthLayers = availableDepthLayers;
        this.availableGroupTypes = availableGroupTypes;
        this.availableParticleTypes = availableParticleTypes;
        this.showDepthLayer = showDepthLayer;
        this.showGroupKind = showGroupKind;
        this.showParticleKind = showParticleKind;
    }
}

export function ParticleFilter_$reflection() {
    return record_type("Atlantis.Types.ParticleFilter", [], ParticleFilter, () => [["fetched", bool_type], ["availableFieldTypes", list_type(FieldKind_$reflection())], ["availableDepthLayers", list_type(LayerType_$reflection())], ["availableGroupTypes", list_type(GroupType_$reflection())], ["availableParticleTypes", list_type(ParticleType_$reflection())], ["showDepthLayer", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [int32_type, bool_type])], ["showGroupKind", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [GroupKind_$reflection(), bool_type])], ["showParticleKind", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [ParticleKind_$reflection(), bool_type])]]);
}

export function ParticleFilter_get_empty() {
    return new ParticleFilter(false, empty(), singleton(new LayerType(0, new DepthKind(0, []), new DepthKind(1, []))), singleton(new GroupType(0, new GroupKind(0, []), "AnyGroup")), singleton(new ParticleType(0, new ParticleKind(0, []), "AnyParticle")), ofList(singleton([0, true]), {
        Compare: comparePrimitives,
    }), ofList(singleton([new GroupKind(0, []), true]), {
        Compare: compare,
    }), ofList(singleton([new ParticleKind(0, []), true]), {
        Compare: compare,
    }));
}

export class Prop extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Bathy", "Temp", "Salt", "Zeta", "Speed", "Conc", "Sed", "WC", "DW", "Map"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? "temp" : ((x.tag === 2) ? "salt" : ((x.tag === 3) ? "zeta" : ((x.tag === 4) ? "speed" : ((x.tag === 5) ? ((x.fields[0].tag === 1) ? "concentration_average" : ((x.fields[0].tag === 2) ? "concentration_accumulated" : ((x.fields[0].tag === 3) ? ((x.fields[0].fields[0].tag === 1) ? "concentration_daily" : ((x.fields[0].fields[0].tag === 2) ? "concentration_weekly" : ((x.fields[0].fields[0].tag === 3) ? "concentration_monthly" : ((x.fields[0].fields[0].tag === 4) ? "concentration_quarterly" : "concentration_tidal")))) : "concentration_instant"))) : ((x.tag === 6) ? ((x.fields[0].tag === 1) ? "sedimentation_average" : ((x.fields[0].tag === 2) ? "sedimentation_accumulated" : ((x.fields[0].tag === 3) ? ((x.fields[0].fields[0].tag === 1) ? "sedimentation_daily" : ((x.fields[0].fields[0].tag === 2) ? "sedimentation_weekly" : ((x.fields[0].fields[0].tag === 3) ? "sedimentation_monthly" : ((x.fields[0].fields[0].tag === 4) ? "sedimentation_quarterly" : "sedimentation_tidal")))) : "sedimentation_instant"))) : ((x.tag === 7) ? ((x.fields[0].tag === 1) ? "wc_average" : ((x.fields[0].tag === 2) ? "wc_accumulated" : ((x.fields[0].tag === 3) ? ((x.fields[0].fields[0].tag === 1) ? "wc_daily" : ((x.fields[0].fields[0].tag === 2) ? "wc_weekly" : ((x.fields[0].fields[0].tag === 3) ? "wc_monthly" : ((x.fields[0].fields[0].tag === 4) ? "wc_quarterly" : "wc_tidal")))) : "wc_instant"))) : ((x.tag === 8) ? ((x.fields[0].tag === 1) ? "dw_average" : ((x.fields[0].tag === 2) ? "dw_accumulated" : ((x.fields[0].tag === 3) ? ((x.fields[0].fields[0].tag === 1) ? "dw_daily" : ((x.fields[0].fields[0].tag === 2) ? "dw_weekly" : ((x.fields[0].fields[0].tag === 3) ? "dw_monthly" : ((x.fields[0].fields[0].tag === 4) ? "dw_quarterly" : "dw_tidal")))) : "dw_instant"))) : ((x.tag === 9) ? "map" : "bathy"))))))));
    }
}

export function Prop_$reflection() {
    return union_type("Atlantis.Types.Prop", [], Prop, () => [[], [], [], [], [], [["Item", FieldKind_$reflection()]], [["Item", FieldKind_$reflection()]], [["Item", FieldKind_$reflection()]], [["Item", FieldKind_$reflection()]], []]);
}

export function Prop__ToLabel(x) {
    switch (x.tag) {
        case 1:
            return "Temperature";
        case 2:
            return "Salinity";
        case 3:
            return "Tide";
        case 4:
            return "Current";
        case 5:
            switch (x.fields[0].tag) {
                case 1:
                    return "Average concentration";
                case 2:
                    return "Accumulated concentration";
                case 3:
                    switch (x.fields[0].fields[0].tag) {
                        case 1:
                            return "Smoothed concentration (24h)";
                        case 2:
                            return "Smoothed concentration (7d)";
                        case 3:
                            return "Smoothed concentration (4w)";
                        case 4:
                            return "Smoothed concentration (3m)";
                        default:
                            return "Smoothed concentration (6h)";
                    }
                default:
                    return "Instant concentration";
            }
        case 6:
            switch (x.fields[0].tag) {
                case 1:
                    return "Average sedimentation";
                case 2:
                    return "Accumulated sedimentation";
                case 3:
                    switch (x.fields[0].fields[0].tag) {
                        case 1:
                            return "Smoothed sedimentation (24h)";
                        case 2:
                            return "Smoothed sedimentation (7d)";
                        case 3:
                            return "Smoothed sedimentation (4w)";
                        case 4:
                            return "Smoothed sedimentation (3m)";
                        default:
                            return "Smoothed sedimentation (6h)";
                    }
                default:
                    return "Instant sedimentation";
            }
        case 7:
            switch (x.fields[0].tag) {
                case 1:
                    return "Average water contact";
                case 2:
                    return "Accumulated water contact";
                case 3:
                    switch (x.fields[0].fields[0].tag) {
                        case 1:
                            return "Smoothed water contact (24h)";
                        case 2:
                            return "Smoothed water contact (7d)";
                        case 3:
                            return "Smoothed water contact (4w)";
                        case 4:
                            return "Smoothed water contact (3m)";
                        default:
                            return "Smoothed water contact (6h)";
                    }
                default:
                    return "Instant water contact";
            }
        case 8:
            switch (x.fields[0].tag) {
                case 1:
                    return "Average downwelling";
                case 2:
                    return "Accumulated downwelling";
                case 3:
                    switch (x.fields[0].fields[0].tag) {
                        case 1:
                            return "Smoothed downwelling (24h)";
                        case 2:
                            return "Smoothed downwelling (7d)";
                        case 3:
                            return "Smoothed downwelling (4w)";
                        case 4:
                            return "Smoothed downwelling (3m)";
                        default:
                            return "Smoothed downwelling (6h)";
                    }
                default:
                    return "Instant downwelling";
            }
        case 9:
            return "Map";
        default:
            return "Bathymetry";
    }
}

export function Prop__get_viewRange(x) {
    switch (x.tag) {
        case 1:
            return [0, 15];
        case 2:
            return [27.5, 35];
        case 3:
            return [-1.5, 1.5];
        case 4:
            return [0, 1];
        case 5:
            return [0, 1];
        case 6:
            return [0, 10];
        case 7:
            return [-100, 0];
        case 8:
            return [0, 1000];
        case 0:
            return [0, 500];
        default:
            return [0, 1];
    }
}

export function Prop__get_minMax(x) {
    switch (x.tag) {
        case 1:
            return [-5, 45];
        case 2:
            return [0, 50];
        case 3:
            return [-5, 5];
        case 4:
            return [0, 5];
        case 5:
            return [0, 100];
        case 6:
            return [0, 1000];
        case 7:
            return [-100, 0];
        case 8:
            return [0, 10000];
        case 0:
            return [0, 1500];
        default:
            return [0, 1];
    }
}

export function Prop__get_unit(x) {
    switch (x.tag) {
        case 1:
            return "°C";
        case 2:
            return "psu";
        case 3:
            return "m";
        case 4:
            return "m/s";
        case 5:
            return "1/km2";
        case 6:
            return "g/m2";
        case 7:
            return "";
        case 8:
            return "ml/l";
        case 9:
            return "";
        default:
            return "m";
    }
}

export function Prop_fromString_Z721C83C5(str) {
    const matchValue = str.toLocaleLowerCase();
    switch (matchValue) {
        case "bathy":
            return new Prop(0, []);
        case "zeta":
            return new Prop(3, []);
        case "temp":
            return new Prop(1, []);
        case "salt":
            return new Prop(2, []);
        case "speed":
            return new Prop(4, []);
        case "concentration_instant":
            return new Prop(5, [new FieldKind(0, [])]);
        case "concentration_average":
            return new Prop(5, [new FieldKind(1, [])]);
        case "concentration_accumulated":
            return new Prop(5, [new FieldKind(2, [])]);
        case "concentration_tidal":
            return new Prop(5, [new FieldKind(3, [new TemporalSmoothing(0, [])])]);
        case "concentration_daily":
            return new Prop(5, [new FieldKind(3, [new TemporalSmoothing(1, [])])]);
        case "concentration_weekly":
            return new Prop(5, [new FieldKind(3, [new TemporalSmoothing(2, [])])]);
        case "concentration_monthly":
            return new Prop(5, [new FieldKind(3, [new TemporalSmoothing(3, [])])]);
        case "concentration_quarterly":
            return new Prop(5, [new FieldKind(3, [new TemporalSmoothing(4, [])])]);
        case "sedimentation_instant":
            return new Prop(6, [new FieldKind(0, [])]);
        case "sedimentation_average":
            return new Prop(6, [new FieldKind(1, [])]);
        case "sedimentation_accumulated":
            return new Prop(6, [new FieldKind(2, [])]);
        case "sedimentation_tidal":
            return new Prop(6, [new FieldKind(3, [new TemporalSmoothing(0, [])])]);
        case "sedimentation_daily":
            return new Prop(6, [new FieldKind(3, [new TemporalSmoothing(1, [])])]);
        case "sedimentation_weekly":
            return new Prop(6, [new FieldKind(3, [new TemporalSmoothing(2, [])])]);
        case "sedimentation_monthly":
            return new Prop(6, [new FieldKind(3, [new TemporalSmoothing(3, [])])]);
        case "sedimentation_quarterly":
            return new Prop(6, [new FieldKind(3, [new TemporalSmoothing(4, [])])]);
        case "wc_instant":
            return new Prop(7, [new FieldKind(0, [])]);
        case "wc_average":
            return new Prop(7, [new FieldKind(1, [])]);
        case "wc_accumulated":
            return new Prop(7, [new FieldKind(2, [])]);
        case "wc_tidal":
            return new Prop(7, [new FieldKind(3, [new TemporalSmoothing(0, [])])]);
        case "wc_daily":
            return new Prop(7, [new FieldKind(3, [new TemporalSmoothing(1, [])])]);
        case "wc_weekly":
            return new Prop(7, [new FieldKind(3, [new TemporalSmoothing(2, [])])]);
        case "wc_monthly":
            return new Prop(7, [new FieldKind(3, [new TemporalSmoothing(3, [])])]);
        case "wc_quarterly":
            return new Prop(7, [new FieldKind(3, [new TemporalSmoothing(4, [])])]);
        case "dw_instant":
            return new Prop(8, [new FieldKind(0, [])]);
        case "dw_average":
            return new Prop(8, [new FieldKind(1, [])]);
        case "dw_accumulated":
            return new Prop(8, [new FieldKind(2, [])]);
        case "dw_tidal":
            return new Prop(8, [new FieldKind(3, [new TemporalSmoothing(0, [])])]);
        case "dw_daily":
            return new Prop(8, [new FieldKind(3, [new TemporalSmoothing(1, [])])]);
        case "dw_weekly":
            return new Prop(8, [new FieldKind(3, [new TemporalSmoothing(2, [])])]);
        case "dw_monthly":
            return new Prop(8, [new FieldKind(3, [new TemporalSmoothing(3, [])])]);
        case "dw_quarterly":
            return new Prop(8, [new FieldKind(3, [new TemporalSmoothing(4, [])])]);
        default:
            return new Prop(9, []);
    }
}

export function Prop_findRange_5ACB89F7(props) {
    return [min(props, {
        Compare: compare,
    }), max(props, {
        Compare: compare,
    })];
}

export class Spinner extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Spinning", "Progress"];
    }
}

export function Spinner_$reflection() {
    return union_type("Atlantis.Types.Spinner", [], Spinner, () => [[], []]);
}

export class MapLayer extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Ocean", "Conc", "Heatmap", "Aux", "Aze", "Particles", "Crop", "Streams", "Wind", "Wireframe", "Draw", "Simulations", "SelectedReleaseGroup", "UnselectedReleaseGroups", "GridMarkers", "GeoFences", "Aquaculture", "Networks"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? "concgl" : ((x.tag === 3) ? "auxgl" : ((x.tag === 4) ? "aze" : ((x.tag === 6) ? "cropgl" : ((x.tag === 5) ? "particles" : ((x.tag === 7) ? "streams" : ((x.tag === 8) ? "wind" : ((x.tag === 9) ? "wireframe" : ((x.tag === 2) ? "heatmap" : ((x.tag === 10) ? "draw" : ((x.tag === 11) ? "simulation-sites" : ((x.tag === 12) ? "selected-release-group" : ((x.tag === 13) ? "unselected-release-groups" : ((x.tag === 14) ? "grid-markers" : ((x.tag === 15) ? "geo-fences" : ((x.tag === 16) ? "aquaculture" : ((x.tag === 17) ? "networks" : "basegl"))))))))))))))));
    }
}

export function MapLayer_$reflection() {
    return union_type("Atlantis.Types.MapLayer", [], MapLayer, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);
}

export function MapLayer__Label(x) {
    switch (x.tag) {
        case 1:
            return "Concentration";
        case 3:
            return "Field";
        case 4:
            return "AZE";
        case 6:
            return "Crop";
        case 9:
            return "Wireframe";
        case 2:
            return "Heatmap";
        case 5:
            return "Particels";
        case 7:
            return "Streams";
        case 8:
            return "Wind";
        case 10:
            return "Draw";
        case 11:
            return "Simulation sites";
        case 12:
            return "Selected releases";
        case 13:
            return "Unselected releases";
        case 14:
            return "Grid";
        case 15:
            return "Fences";
        case 16:
            return "Aquaculture";
        case 17:
            return "Networks";
        default:
            return "Ocean";
    }
}

export function MapLayer__get_Attenuate(x) {
    switch (x.tag) {
        case 1:
        case 3:
            return 0.85;
        case 6:
            return 1;
        default:
            return 0;
    }
}

export function MapLayer__get_Id(x) {
    return toString(x);
}

export function MapLayer_get_All() {
    return [new MapLayer(0, []), new MapLayer(1, []), new MapLayer(2, []), new MapLayer(3, []), new MapLayer(4, []), new MapLayer(5, []), new MapLayer(6, []), new MapLayer(7, []), new MapLayer(8, []), new MapLayer(9, []), new MapLayer(10, []), new MapLayer(11, []), new MapLayer(12, []), new MapLayer(13, []), new MapLayer(14, []), new MapLayer(15, []), new MapLayer(16, []), new MapLayer(17, [])];
}

export class InfoLayer extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Gyting", "Beite", "Korall", "Vern", "POs", "Lokaliteter"];
    }
    toString() {
        const x = this;
        return (x.tag === 1) ? "oppvekst_beiteomraade" : ((x.tag === 2) ? "korallrev_forbudsomraade" : ((x.tag === 3) ? "verneomraader_bunnhabitat" : ((x.tag === 4) ? "produksjonsomraader" : ((x.tag === 5) ? "akvakultur_lokaliteter" : "gyteomraader"))));
    }
}

export function InfoLayer_$reflection() {
    return union_type("Atlantis.Types.InfoLayer", [], InfoLayer, () => [[], [], [], [], [], []]);
}

export function InfoLayer__wmsUrl(x) {
    return `https://gis.fiskeridir.no/server/services/${(x.tag === 1) ? "fiskeridirWMS" : ((x.tag === 2) ? "fiskeridirWMS" : ((x.tag === 3) ? "fiskeridirWMS" : ((x.tag === 4) ? "fiskeridirWMS_akva" : ((x.tag === 5) ? "fiskeridirWMS_akva" : "fiskeridirWMS"))))}/MapServer/WMSServer`;
}

export function InfoLayer__wfsUrl(x) {
    const svc = (x.tag === 1) ? "fiskeridirWFS" : ((x.tag === 2) ? "fiskeridirWFS" : ((x.tag === 3) ? "fiskeridirWFS" : ((x.tag === 4) ? "FiskeridirWFS_akva" : ((x.tag === 5) ? "FiskeridirWFS_akva" : "fiskeridirWFS"))));
    return "https://gis.fiskeridir.no/server/services/FiskeridirWFS/MapServer/WFSServer";
}

export class ColorMode extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Normal", "Mirror", "Gray"];
    }
}

export function ColorMode_$reflection() {
    return union_type("Atlantis.Types.ColorMode", [], ColorMode, () => [[], [], []]);
}

export class PropColor extends Record {
    constructor(LogScale, ViewRange, PropRange, ColorMap) {
        super();
        this.LogScale = LogScale;
        this.ViewRange = ViewRange;
        this.PropRange = PropRange;
        this.ColorMap = ColorMap;
    }
}

export function PropColor_$reflection() {
    return record_type("Atlantis.Types.PropColor", [], PropColor, () => [["LogScale", bool_type], ["ViewRange", tuple_type(float64_type, float64_type)], ["PropRange", tuple_type(float64_type, float64_type)], ["ColorMap", tuple_type(ColorMode_$reflection(), string_type)]]);
}

export function PropColor_get_empty() {
    return new PropColor(false, [0, 1], [0, 1], [new ColorMode(0, []), "thermal"]);
}

export class ViewProp extends Record {
    constructor(PropType, PropData, Alpha) {
        super();
        this.PropType = PropType;
        this.PropData = PropData;
        this.Alpha = Alpha;
    }
}

export function ViewProp_$reflection() {
    return record_type("Atlantis.Types.ViewProp", [], ViewProp, () => [["PropType", Prop_$reflection()], ["PropData", array_type(float32_type)], ["Alpha", float64_type]]);
}

export function ViewProp_get_empty() {
    return new ViewProp(new Prop(9, []), new Float32Array([]), 1);
}

export class WireframeGrid extends Record {
    constructor(Vertices, BarycentricCoords) {
        super();
        this.Vertices = Vertices;
        this.BarycentricCoords = BarycentricCoords;
    }
}

export function WireframeGrid_$reflection() {
    return record_type("Atlantis.Types.WireframeGrid", [], WireframeGrid, () => [["Vertices", array_type(float32_type)], ["BarycentricCoords", array_type(int32_type)]]);
}

export function WireframeGrid_get_empty() {
    return new WireframeGrid(new Float32Array(0), new Int32Array(0));
}

export class DBPlainGrid extends Record {
    constructor(ArchiveUuid, Grid) {
        super();
        this.ArchiveUuid = ArchiveUuid;
        this.Grid = Grid;
    }
}

export function DBPlainGrid_$reflection() {
    return record_type("Atlantis.Types.DBPlainGrid", [], DBPlainGrid, () => [["ArchiveUuid", class_type("System.Guid")], ["Grid", PlainGrid_$reflection()]]);
}

export function DBPlainGrid_get_key() {
    return "PlainGrids";
}

export class DBWireframeGrid extends Record {
    constructor(ArchiveUuid, Grid) {
        super();
        this.ArchiveUuid = ArchiveUuid;
        this.Grid = Grid;
    }
}

export function DBWireframeGrid_$reflection() {
    return record_type("Atlantis.Types.DBWireframeGrid", [], DBWireframeGrid, () => [["ArchiveUuid", class_type("System.Guid")], ["Grid", WireframeGrid_$reflection()]]);
}

export function DBWireframeGrid_get_key() {
    return "WireframeGrids";
}

export class DBArchivePolygon extends Record {
    constructor(ArchiveUuid, Polygon) {
        super();
        this.ArchiveUuid = ArchiveUuid;
        this.Polygon = Polygon;
    }
}

export function DBArchivePolygon_$reflection() {
    return record_type("Atlantis.Types.DBArchivePolygon", [], DBArchivePolygon, () => [["ArchiveUuid", class_type("System.Guid")], ["Polygon", array_type(tuple_type(float64_type, float64_type))]]);
}

export function DBArchivePolygon_get_key() {
    return "ArchivePolygons";
}

export class ArchiveInfo extends Record {
    constructor(id, name, saveFreq, startTime, defaultZoom, focalPoint, polygon, frames) {
        super();
        this.id = id;
        this.name = name;
        this.saveFreq = (saveFreq | 0);
        this.startTime = startTime;
        this.defaultZoom = defaultZoom;
        this.focalPoint = focalPoint;
        this.polygon = polygon;
        this.frames = (frames | 0);
    }
}

export function ArchiveInfo_$reflection() {
    return record_type("Atlantis.Types.ArchiveInfo", [], ArchiveInfo, () => [["id", class_type("System.Guid")], ["name", string_type], ["saveFreq", int32_type], ["startTime", class_type("System.DateTime")], ["defaultZoom", float64_type], ["focalPoint", tuple_type(float64_type, float64_type)], ["polygon", option_type(array_type(tuple_type(float64_type, float64_type)))], ["frames", int32_type]]);
}

export function ArchiveInfo_get_empty() {
    return new ArchiveInfo("00000000-0000-0000-0000-000000000000", "", 0, minValue(), 10, [0, 0], void 0, 0);
}

export class SimArchive extends Record {
    constructor(Archive, Duration, Reverse, Status, JobId, SimType, SimFormat) {
        super();
        this.Archive = Archive;
        this.Duration = Duration;
        this.Reverse = Reverse;
        this.Status = (Status | 0);
        this.JobId = JobId;
        this.SimType = SimType;
        this.SimFormat = SimFormat;
    }
}

export function SimArchive_$reflection() {
    return record_type("Atlantis.Types.SimArchive", [], SimArchive, () => [["Archive", Types_ArchiveProps_$reflection()], ["Duration", class_type("System.TimeSpan")], ["Reverse", bool_type], ["Status", enum_type("Hipster.Interfaces.Job.JobStatus", int32_type, [["New", 0], ["Waiting", 1], ["Running", 2], ["Completed", 3], ["Failed", 4], ["Unknown", 5]])], ["JobId", option_type(int32_type)], ["SimType", Types_DriftersVariant_$reflection()], ["SimFormat", Types_DriftersFormat_$reflection()]]);
}

export function SimArchive_Create_Z2F6EA0DD(sim) {
    let inputRecord, matchValue;
    return new SimArchive((inputRecord = Types_ArchiveProps_get_empty(), new Types_ArchiveProps(inputRecord.archiveId, sim.aid, inputRecord.modelArea, inputRecord.name, inputRecord.description, inputRecord.archiveType, inputRecord.projection, inputRecord.focalPoint, inputRecord.defaultZoom, ~~SaveFrequency__ToFloat(sim.saveFreq), inputRecord.frames, sim.startTime, inputRecord.endTime, inputRecord.created, inputRecord.owner, inputRecord.expires, inputRecord.isPublished, inputRecord.isPublic, inputRecord.polygon, inputRecord.json)), fromDays(sim.simDays), sim.reverse, 0, void 0, (matchValue = sim.kind, (matchValue.tag === 1) ? (new Types_DriftersVariant(1, [])) : ((matchValue.tag === 2) ? (new Types_DriftersVariant(2, [])) : ((matchValue.tag === 3) ? (new Types_DriftersVariant(3, [])) : ((matchValue.tag === 5) ? (new Types_DriftersVariant(5, [])) : ((matchValue.tag === 4) ? (new Types_DriftersVariant(6, [])) : (new Types_DriftersVariant(0, []))))))), new Types_DriftersFormat(0, []));
}

export function SimArchive_Create_Z424380DE(ana) {
    let inputRecord, matchValue, matchValue_1;
    const tStart = defaultArg(ana.startTime, now());
    const tEnd = defaultArg(ana.endTime, now());
    return new SimArchive((inputRecord = Types_ArchiveProps_get_empty(), new Types_ArchiveProps(inputRecord.archiveId, inputRecord.reference, inputRecord.modelArea, inputRecord.name, inputRecord.description, inputRecord.archiveType, inputRecord.projection, inputRecord.focalPoint, inputRecord.defaultZoom, ~~SaveFrequency__ToFloat(ana.saveFreq), inputRecord.frames, tStart, inputRecord.endTime, inputRecord.created, inputRecord.owner, inputRecord.expires, inputRecord.isPublished, inputRecord.isPublic, inputRecord.polygon, inputRecord.json)), op_Subtraction(tEnd, tStart), ana.reverse, 0, void 0, (matchValue = ana.kind, (matchValue.tag === 1) ? (new Types_DriftersVariant(1, [])) : ((matchValue.tag === 2) ? (new Types_DriftersVariant(2, [])) : ((matchValue.tag === 3) ? (new Types_DriftersVariant(3, [])) : ((matchValue.tag === 5) ? (new Types_DriftersVariant(5, [])) : ((matchValue.tag === 4) ? (new Types_DriftersVariant(6, [])) : (new Types_DriftersVariant(0, []))))))), (matchValue_1 = ana.kind, (matchValue_1.tag === 1) ? (new Types_DriftersFormat(2, [])) : ((matchValue_1.tag === 2) ? (new Types_DriftersFormat(2, [])) : ((matchValue_1.tag === 3) ? (new Types_DriftersFormat(1, [])) : ((matchValue_1.tag === 5) ? (new Types_DriftersFormat(1, [])) : ((matchValue_1.tag === 4) ? (new Types_DriftersFormat(2, [])) : (new Types_DriftersFormat(2, []))))))));
}

export class SideNavMode extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["OceanControls", "SimControls", "AnalysisControls", "ColorControls", "LayerControls", "StatsControls", "CropControls"];
    }
}

export function SideNavMode_$reflection() {
    return union_type("Atlantis.Types.SideNavMode", [], SideNavMode, () => [[], [["Item", SimType_$reflection()]], [["Item", SimArchive_$reflection()]], [], [], [], []]);
}

export function SideNavMode__ToLabel(this$) {
    switch (this$.tag) {
        case 1:
            return `Simulation controls ${SimType__ToLabel(this$.fields[0])}`;
        case 2:
            return "Analysis controls";
        case 3:
            return "Color controls";
        case 4:
            return "Layer controls";
        case 5:
            return "Stats controls";
        case 6:
            return "Crop controls";
        default:
            return "Ocean controls";
    }
}

export class Props extends Record {
    constructor(arrows) {
        super();
        this.arrows = arrows;
    }
}

export function Props_$reflection() {
    return record_type("Atlantis.Types.Props", [], Props, () => [["arrows", array_type(Arrow$1_$reflection(float64_type))]]);
}

export function Props_get_empty() {
    return new Props([]);
}

export const HeatMap_defR = 2;

export const HeatMap_defBlr = 25;

export class LineData extends Record {
    constructor(controlPoints, bezier, sharpness, resolution) {
        super();
        this.controlPoints = controlPoints;
        this.bezier = bezier;
        this.sharpness = sharpness;
        this.resolution = (resolution | 0);
    }
}

export function LineData_$reflection() {
    return record_type("Atlantis.Types.LineData", [], LineData, () => [["controlPoints", array_type(tuple_type(float64_type, float64_type))], ["bezier", bool_type], ["sharpness", float64_type], ["resolution", int32_type]]);
}

export function LineData_get_empty() {
    return new LineData([[0.12, 0.6]], true, 0.8, 10000);
}

export class NetworkState extends Record {
    constructor(selectedSite, clickCount, matrix, lineData) {
        super();
        this.selectedSite = selectedSite;
        this.clickCount = (clickCount | 0);
        this.matrix = matrix;
        this.lineData = lineData;
    }
}

export function NetworkState_$reflection() {
    return record_type("Atlantis.Types.NetworkState", [], NetworkState, () => [["selectedSite", option_type(int32_type)], ["clickCount", int32_type], ["matrix", array_type(tuple_type(class_type("Fable.OpenLayers.Geometry.Circle"), array_type(float64_type)))], ["lineData", LineData_$reflection()]]);
}

export function NetworkState_get_empty() {
    return new NetworkState(void 0, 0, [], LineData_get_empty());
}

