import { Record } from "./fable_modules/fable-library.4.5.0/Types.js";
import { record_type, int32_type } from "./fable_modules/fable-library.4.5.0/Reflection.js";
import { Auto_generateBoxedDecoder_Z6670B51 } from "./fable_modules/Thoth.Json.10.2.0/Decode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.10.2.0/Decode.fs.js";
import { uncurry2 } from "./fable_modules/fable-library.4.5.0/Util.js";
import { sleep, startImmediate } from "./fable_modules/fable-library.4.5.0/Async.js";
import { singleton } from "./fable_modules/fable-library.4.5.0/AsyncBuilder.js";
import { authApi } from "./Remoting.js";
import { some } from "./fable_modules/fable-library.4.5.0/Option.js";
import { split } from "./fable_modules/fable-library.4.5.0/String.js";
import { fromDate, toUnixTimeSeconds } from "./fable_modules/fable-library.4.5.0/DateOffset.js";
import { now } from "./fable_modules/fable-library.4.5.0/Date.js";
import { toInt32, compare, fromInt32, op_Subtraction, toInt64 } from "./fable_modules/fable-library.4.5.0/BigInt.js";

class JwtToken extends Record {
    constructor(exp) {
        super();
        this.exp = (exp | 0);
    }
}

function JwtToken_$reflection() {
    return record_type("Auth.JwtToken", [], JwtToken, () => [["exp", int32_type]]);
}

const jwtDecoder = (() => {
    const decoder = Auto_generateBoxedDecoder_Z6670B51(JwtToken_$reflection(), void 0, void 0);
    return (value) => fromString(uncurry2(decoder), value);
})();

export function establishAuthentication() {
    startImmediate(singleton.Delay(() => singleton.Bind(authApi.IsAuthenticated(), (_arg) => {
        if (_arg == null) {
            console.log(some("not yet authenticated"));
            window.location.href = "/signin";
            return singleton.Zero();
        }
        else {
            console.log(some("already authenticated"));
            return singleton.Zero();
        }
    })));
}

export function tokenRefreshLoop() {
    startImmediate(singleton.Delay(() => {
        const decode = (token) => {
            let x;
            const _arg = jwtDecoder((x = split(localStorage[token], ["."], void 0, 0), atob(x[1])));
            if (_arg.tag === 1) {
                console.log(some(_arg.fields[0]));
                return 0;
            }
            else {
                return _arg.fields[0].exp | 0;
            }
        };
        const t_1 = toUnixTimeSeconds(fromDate(now()));
        return singleton.Bind(sleep(1000), () => {
            const aExp = decode("access_token") | 0;
            const rExp = decode("refresh_token") | 0;
            const dtA = toInt64(op_Subtraction(toInt64(fromInt32(aExp)), t_1));
            const dtR = toInt64(op_Subtraction(toInt64(fromInt32(rExp)), t_1));
            console.log(some(`${t_1} ${dtA} ${dtR}`));
            return singleton.Combine((compare(dtR, toInt64(fromInt32(0))) < 0) ? ((window.location.href = "/signin", singleton.Zero())) : singleton.Zero(), singleton.Delay(() => singleton.Combine((compare(dtA, toInt64(fromInt32(30))) < 0) ? ((startImmediate(singleton.Delay(() => singleton.Bind(authApi.RefreshAccessToken(localStorage["refresh_token"]), (_arg_2) => {
                if (_arg_2 == null) {
                    window.location.href = "/signin";
                    return singleton.Zero();
                }
                else {
                    const refresh = _arg_2[1];
                    const access = _arg_2[0];
                    localStorage.setItem("access_token", access);
                    localStorage.setItem("refresh_token", refresh);
                    return singleton.Zero();
                }
            }))), singleton.Zero())) : singleton.Zero(), singleton.Delay(() => singleton.Bind(sleep((~~toInt32(dtA) - 30) * 1000), () => {
                tokenRefreshLoop();
                return singleton.Zero();
            })))));
        });
    }));
}

export function startTokenRefreshLoopOrLogin() {
    return singleton.Delay(() => singleton.Bind(authApi.IsAuthenticated(), (_arg) => {
        if (_arg == null) {
            console.log(some("not already authenticated"));
            window.location.href = "/login";
            return singleton.Zero();
        }
        else {
            console.log(some("already authenticated"));
            tokenRefreshLoop();
            return singleton.Zero();
        }
    }));
}

