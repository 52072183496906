import { Remoting_buildProxy_64DC51C } from "./fable_modules/Fable.Remoting.Client.7.32.0/Remoting.fs.js";
import { RemotingModule_withBinarySerialization, RemotingModule_withBaseUrl, RemotingModule_createApi, RemotingModule_withCredentials, RemotingModule_withRouteBuilder } from "./fable_modules/Fable.Remoting.Client.7.32.0/Remoting.fs.js";
import { Api_Drifters_$reflection, Api_Inbox_$reflection, Api_Services_$reflection, Api_Auth_$reflection, Api_routeBuilder } from "./Shared/Shared.js";
import { Api_Archive_$reflection, Api_apiRouteBuilder, Api_Inventory_$reflection } from "./Interfaces/Archmeister/ApiTypes.js";
import { class_type } from "./fable_modules/fable-library.4.5.0/Reflection.js";
import { Api_Crop_Stats_$reflection, Api_Crop_Fvcom_$reflection, Api_Crop_routeBuilder, Api_Proximity_Prox_$reflection, Api_Proximity_routeBuilder, Api_Stats_routeBuilder, Api_Stats_FvStatsSeries_$reflection, Api_Stats_FvStatsByIndex_$reflection, Api_Stats_FvStatsByLayer_$reflection, Api_Stats_FvStatsInfo_$reflection, Api_Atmo_routeBuilder, Api_Atmo_Wind_$reflection, Api_Drifters_routeBuilder, Api_Drifters_Network_$reflection, Api_Drifters_Field3D_$reflection, Api_Drifters_Field2D_$reflection, Api_Drifters_Sedimentation_$reflection, Api_Drifters_FieldMetaData_$reflection, Api_Drifters_Particles_$reflection, Api_Drifters_Archive_$reflection, Api_Fvcom_routeBuilder, Api_Fvcom_TimeSeries_$reflection, Api_Fvcom_Batch_$reflection, Api_Fvcom_Element_$reflection, Api_Fvcom_Node_$reflection, Api_Fvcom_Layer_$reflection, Api_Fvcom_Archive_$reflection } from "./Interfaces/Sorcerer/ApiTypes.js";
import { uncurry2 } from "./fable_modules/fable-library.4.5.0/Util.js";

export const authApi = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(Api_routeBuilder, RemotingModule_withCredentials(true, RemotingModule_createApi())), Api_Auth_$reflection());

export const servicesApi = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(Api_routeBuilder, RemotingModule_withCredentials(true, RemotingModule_createApi())), Api_Services_$reflection());

export function inboxApi() {
    return Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(Api_routeBuilder, RemotingModule_withCredentials(true, RemotingModule_createApi())), Api_Inbox_$reflection());
}

export class ArchivesApi {
    constructor(serverUrl) {
        this.serverUrl = serverUrl;
        this["Archive@"] = ArchivesApi__createApi_291EB319(this, (options) => Remoting_buildProxy_64DC51C(options, Api_Inventory_$reflection()));
    }
}

export function ArchivesApi_$reflection() {
    return class_type("Remoting.ArchivesApi", void 0, ArchivesApi);
}

export function ArchivesApi_$ctor_Z721C83C5(serverUrl) {
    return new ArchivesApi(serverUrl);
}

export function ArchivesApi__get_Archive(__) {
    return __["Archive@"];
}

export function ArchivesApi__createApi_291EB319(this$, f) {
    return f(RemotingModule_withRouteBuilder(Api_apiRouteBuilder, RemotingModule_withBaseUrl(this$.serverUrl, RemotingModule_withCredentials(true, RemotingModule_createApi()))));
}

export class FvcomApi {
    constructor(url) {
        this.url = url;
        this["Archive@"] = FvcomApi__createApi_291EB319(this, (options) => Remoting_buildProxy_64DC51C(options, Api_Fvcom_Archive_$reflection()));
        this["Layer@"] = FvcomApi__createBinApi_291EB319(this, (options_2) => Remoting_buildProxy_64DC51C(options_2, Api_Fvcom_Layer_$reflection()));
        this["Node@"] = FvcomApi__createBinApi_291EB319(this, (options_4) => Remoting_buildProxy_64DC51C(options_4, Api_Fvcom_Node_$reflection()));
        this["Element@"] = FvcomApi__createBinApi_291EB319(this, (options_6) => Remoting_buildProxy_64DC51C(options_6, Api_Fvcom_Element_$reflection()));
        this["Batch@"] = FvcomApi__createBinApi_291EB319(this, (options_8) => Remoting_buildProxy_64DC51C(options_8, Api_Fvcom_Batch_$reflection()));
        this["TimeSeries@"] = FvcomApi__createBinApi_291EB319(this, (options_10) => Remoting_buildProxy_64DC51C(options_10, Api_Fvcom_TimeSeries_$reflection()));
    }
}

export function FvcomApi_$reflection() {
    return class_type("Remoting.FvcomApi", void 0, FvcomApi);
}

export function FvcomApi_$ctor_Z721C83C5(url) {
    return new FvcomApi(url);
}

export function FvcomApi__get_Archive(__) {
    return __["Archive@"];
}

export function FvcomApi__get_Layer(__) {
    return __["Layer@"];
}

export function FvcomApi__get_Node(__) {
    return __["Node@"];
}

export function FvcomApi__get_Element(__) {
    return __["Element@"];
}

export function FvcomApi__get_Batch(__) {
    return __["Batch@"];
}

export function FvcomApi__get_TimeSeries(__) {
    return __["TimeSeries@"];
}

export function FvcomApi__createBinApi_291EB319(this$, f) {
    return f(RemotingModule_withBinarySerialization(RemotingModule_withRouteBuilder(uncurry2(Api_Fvcom_routeBuilder), RemotingModule_withBaseUrl(this$.url, RemotingModule_withCredentials(true, RemotingModule_createApi())))));
}

export function FvcomApi__createApi_291EB319(this$, f) {
    return f(RemotingModule_withRouteBuilder(uncurry2(Api_Fvcom_routeBuilder), RemotingModule_withBaseUrl(this$.url, RemotingModule_withCredentials(true, RemotingModule_createApi()))));
}

export class DriftersApi {
    constructor(url) {
        this.url = url;
        this["DriftersArchive@"] = DriftersApi__createApi_291EB319(this, (options) => Remoting_buildProxy_64DC51C(options, Api_Drifters_Archive_$reflection()));
        this["Particles@"] = DriftersApi__createBinApi_291EB319(this, (options_2) => Remoting_buildProxy_64DC51C(options_2, Api_Drifters_Particles_$reflection()));
        this["FieldMetaData@"] = DriftersApi__createBinApi_291EB319(this, (options_4) => Remoting_buildProxy_64DC51C(options_4, Api_Drifters_FieldMetaData_$reflection()));
        this["Sedimentation@"] = DriftersApi__createBinApi_291EB319(this, (options_6) => Remoting_buildProxy_64DC51C(options_6, Api_Drifters_Sedimentation_$reflection()));
        this["Field2D@"] = DriftersApi__createBinApi_291EB319(this, (options_8) => Remoting_buildProxy_64DC51C(options_8, Api_Drifters_Field2D_$reflection()));
        this["Field3D@"] = DriftersApi__createBinApi_291EB319(this, (options_10) => Remoting_buildProxy_64DC51C(options_10, Api_Drifters_Field3D_$reflection()));
        this["Network@"] = DriftersApi__createApi_291EB319(this, (options_12) => Remoting_buildProxy_64DC51C(options_12, Api_Drifters_Network_$reflection()));
    }
}

export function DriftersApi_$reflection() {
    return class_type("Remoting.DriftersApi", void 0, DriftersApi);
}

export function DriftersApi_$ctor_Z721C83C5(url) {
    return new DriftersApi(url);
}

export function DriftersApi__get_DriftersArchive(__) {
    return __["DriftersArchive@"];
}

export function DriftersApi__get_Particles(__) {
    return __["Particles@"];
}

export function DriftersApi__get_FieldMetaData(__) {
    return __["FieldMetaData@"];
}

export function DriftersApi__get_Sedimentation(__) {
    return __["Sedimentation@"];
}

export function DriftersApi__get_Field2D(__) {
    return __["Field2D@"];
}

export function DriftersApi__get_Field3D(__) {
    return __["Field3D@"];
}

export function DriftersApi__get_Network(__) {
    return __["Network@"];
}

export function DriftersApi__createBinApi_291EB319(this$, f) {
    return f(RemotingModule_withBinarySerialization(RemotingModule_withRouteBuilder(uncurry2(Api_Drifters_routeBuilder), RemotingModule_withBaseUrl(this$.url, RemotingModule_withCredentials(true, RemotingModule_createApi())))));
}

export function DriftersApi__createApi_291EB319(this$, f) {
    return f(RemotingModule_withRouteBuilder(uncurry2(Api_Drifters_routeBuilder), RemotingModule_withBaseUrl(this$.url, RemotingModule_withCredentials(true, RemotingModule_createApi()))));
}

export class AtmoApi {
    constructor(url) {
        this.url = url;
        this["Wind@"] = AtmoApi__createApi_291EB319(this, (options) => Remoting_buildProxy_64DC51C(options, Api_Atmo_Wind_$reflection()));
    }
}

export function AtmoApi_$reflection() {
    return class_type("Remoting.AtmoApi", void 0, AtmoApi);
}

export function AtmoApi_$ctor_Z721C83C5(url) {
    return new AtmoApi(url);
}

export function AtmoApi__get_Wind(__) {
    return __["Wind@"];
}

export function AtmoApi__createApi_291EB319(this$, f) {
    return f(RemotingModule_withRouteBuilder(uncurry2(Api_Atmo_routeBuilder), RemotingModule_withBaseUrl(this$.url, RemotingModule_withCredentials(true, RemotingModule_createApi()))));
}

export class StatsApi {
    constructor(url) {
        this.url = url;
        this["FvStatsInfo@"] = StatsApi__createApi_291EB319(this, (options) => Remoting_buildProxy_64DC51C(options, Api_Stats_FvStatsInfo_$reflection()));
        this["FvStatsByLayer@"] = StatsApi__createApi_291EB319(this, (arg) => Remoting_buildProxy_64DC51C(RemotingModule_withBinarySerialization(arg), Api_Stats_FvStatsByLayer_$reflection()));
        this["FvStatsByIndex@"] = StatsApi__createApi_291EB319(this, (arg_1) => Remoting_buildProxy_64DC51C(RemotingModule_withBinarySerialization(arg_1), Api_Stats_FvStatsByIndex_$reflection()));
        this["FvStatsSeries@"] = StatsApi__createApi_291EB319(this, (arg_2) => Remoting_buildProxy_64DC51C(RemotingModule_withBinarySerialization(arg_2), Api_Stats_FvStatsSeries_$reflection()));
    }
}

export function StatsApi_$reflection() {
    return class_type("Remoting.StatsApi", void 0, StatsApi);
}

export function StatsApi_$ctor_Z721C83C5(url) {
    return new StatsApi(url);
}

export function StatsApi__get_FvStatsInfo(__) {
    return __["FvStatsInfo@"];
}

export function StatsApi__get_FvStatsByLayer(__) {
    return __["FvStatsByLayer@"];
}

export function StatsApi__get_FvStatsByIndex(__) {
    return __["FvStatsByIndex@"];
}

export function StatsApi__get_FvStatsSeries(__) {
    return __["FvStatsSeries@"];
}

export function StatsApi__createApi_291EB319(this$, f) {
    return f(RemotingModule_withRouteBuilder(uncurry2(Api_Stats_routeBuilder), RemotingModule_withBaseUrl(this$.url, RemotingModule_withCredentials(true, RemotingModule_createApi()))));
}

export function driftersJobApi() {
    return Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(Api_routeBuilder, RemotingModule_withCredentials(true, RemotingModule_createApi())), Api_Drifters_$reflection());
}

export const archmeisterUrl = sessionStorage["archmeister_url"];

export function archiveApi() {
    return Remoting_buildProxy_64DC51C(RemotingModule_withBaseUrl(archmeisterUrl, RemotingModule_withRouteBuilder(Api_apiRouteBuilder, RemotingModule_withCredentials(true, RemotingModule_createApi()))), Api_Archive_$reflection());
}

export function inventoryApi() {
    return Remoting_buildProxy_64DC51C(RemotingModule_withBaseUrl(archmeisterUrl, RemotingModule_withRouteBuilder(Api_apiRouteBuilder, RemotingModule_withCredentials(true, RemotingModule_createApi()))), Api_Inventory_$reflection());
}

export function proximityApi(url) {
    return Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(uncurry2(Api_Proximity_routeBuilder), RemotingModule_withBaseUrl(url, RemotingModule_withCredentials(true, RemotingModule_createApi()))), Api_Proximity_Prox_$reflection());
}

export function cropApi(url) {
    return Remoting_buildProxy_64DC51C(RemotingModule_withBinarySerialization(RemotingModule_withRouteBuilder(uncurry2(Api_Crop_routeBuilder), RemotingModule_withBaseUrl(url, RemotingModule_withCredentials(true, RemotingModule_createApi())))), Api_Crop_Fvcom_$reflection());
}

export function cropStatsApi(url) {
    return Remoting_buildProxy_64DC51C(RemotingModule_withBinarySerialization(RemotingModule_withRouteBuilder(uncurry2(Api_Crop_routeBuilder), RemotingModule_withBaseUrl(url, RemotingModule_withCredentials(true, RemotingModule_createApi())))), Api_Crop_Stats_$reflection());
}

