import { Union } from "../fable-library.4.5.0/Types.js";
import { union_type, int32_type, list_type, string_type } from "../fable-library.4.5.0/Reflection.js";
import { replace, match } from "../fable-library.4.5.0/RegExp.js";
import { head, tail, isEmpty, singleton, cons, empty } from "../fable-library.4.5.0/List.js";
import { contains } from "../fable-library.4.5.0/Seq.js";
import { stringHash } from "../fable-library.4.5.0/Util.js";
import { split, join, toFail } from "../fable-library.4.5.0/String.js";
import { contains as contains_1, map } from "../fable-library.4.5.0/Array.js";

export class ParseResult extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ParseSuccess", "ParseFail"];
    }
}

export function ParseResult_$reflection() {
    return union_type("Lit.Parser.ParseResult", [], ParseResult, () => [[["groups", list_type(string_type)], ["nextIndex", int32_type]], []]);
}

export function stickyRegexToMatcher(r, str, index) {
    let groups;
    const m = match(r, str, index);
    if (!(m != null)) {
        return new ParseResult(1, []);
    }
    else {
        const index_1 = (index + m[0].length) | 0;
        const groupsLen = m.length | 0;
        return new ParseResult(0, [(groupsLen > 1) ? ((groups = empty(), ((() => {
            for (let i = groupsLen - 1; i <= 0; i++) {
                groups = cons(m[i] || "", groups);
            }
        })(), groups))) : singleton(m[0] || ""), index_1]);
    }
}

export const WHITESPACE = (() => {
    const r = /\s+/y;
    return (str) => ((index) => stickyRegexToMatcher(r, str, index));
})();

export function consumeWhitespace(str, index) {
    const matchValue = WHITESPACE(str)(index);
    if (matchValue.tag === 0) {
        return matchValue.fields[1] | 0;
    }
    else {
        return index | 0;
    }
}

export function BETWEEN(quotes, openChar, closeChar, str, index) {
    const tryFindClosing = (openQuote_mut, depth_mut, index_1_mut) => {
        tryFindClosing:
        while (true) {
            const openQuote = openQuote_mut, depth = depth_mut, index_1 = index_1_mut;
            if (index_1 >= str.length) {
                return void 0;
            }
            else if (openQuote == null) {
                const cur = str[index_1];
                if (cur === closeChar) {
                    if (depth === 0) {
                        return index_1;
                    }
                    else {
                        openQuote_mut = void 0;
                        depth_mut = (depth - 1);
                        index_1_mut = (index_1 + 1);
                        continue tryFindClosing;
                    }
                }
                else if (cur === openChar) {
                    openQuote_mut = void 0;
                    depth_mut = (depth + 1);
                    index_1_mut = (index_1 + 1);
                    continue tryFindClosing;
                }
                else if (contains(cur, quotes, {
                    Equals: (x, y) => (x === y),
                    GetHashCode: stringHash,
                }) && (str[index_1 - 1] !== "\\")) {
                    openQuote_mut = cur;
                    depth_mut = depth;
                    index_1_mut = (index_1 + 1);
                    continue tryFindClosing;
                }
                else {
                    openQuote_mut = void 0;
                    depth_mut = depth;
                    index_1_mut = (index_1 + 1);
                    continue tryFindClosing;
                }
            }
            else {
                const openQuote_1 = openQuote;
                if ((str[index_1] === openQuote_1) && (str[index_1 - 1] !== "\\")) {
                    openQuote_mut = void 0;
                    depth_mut = depth;
                    index_1_mut = (index_1 + 1);
                    continue tryFindClosing;
                }
                else {
                    openQuote_mut = openQuote_1;
                    depth_mut = depth;
                    index_1_mut = (index_1 + 1);
                    continue tryFindClosing;
                }
            }
            break;
        }
    };
    if (str[index] === openChar) {
        const matchValue = tryFindClosing(void 0, 0, index + 1);
        if (matchValue != null) {
            const index2 = matchValue | 0;
            return new ParseResult(0, [singleton(str.slice(index, (index2 - 1) + 1)), index2 + 1]);
        }
        else {
            return new ParseResult(0, [singleton(str.slice(index, str.length)), str.length]);
        }
    }
    else {
        return new ParseResult(1, []);
    }
}

export function NEXT_CHAR(quotes, char, str, index) {
    const tryFindChar = (openQuote_mut, index_1_mut) => {
        tryFindChar:
        while (true) {
            const openQuote = openQuote_mut, index_1 = index_1_mut;
            if (index_1 === str.length) {
                return void 0;
            }
            else if (openQuote == null) {
                const cur = str[index_1];
                if (cur === char) {
                    return index_1;
                }
                else if (contains(cur, quotes, {
                    Equals: (x, y) => (x === y),
                    GetHashCode: stringHash,
                }) && (str[index_1 - 1] !== "\\")) {
                    openQuote_mut = cur;
                    index_1_mut = (index_1 + 1);
                    continue tryFindChar;
                }
                else {
                    openQuote_mut = void 0;
                    index_1_mut = (index_1 + 1);
                    continue tryFindChar;
                }
            }
            else {
                const openQuote_1 = openQuote;
                if ((str[index_1] === openQuote_1) && (str[index_1 - 1] !== "\\")) {
                    openQuote_mut = void 0;
                    index_1_mut = (index_1 + 1);
                    continue tryFindChar;
                }
                else {
                    openQuote_mut = openQuote_1;
                    index_1_mut = (index_1 + 1);
                    continue tryFindChar;
                }
            }
            break;
        }
    };
    const matchValue = tryFindChar(void 0, index);
    if (matchValue != null) {
        return new ParseResult(0, [empty(), matchValue + 1]);
    }
    else {
        return new ParseResult(1, []);
    }
}

export const Css_IDENT = (() => {
    const r = /[\w\-]+/y;
    return (str) => ((index) => stickyRegexToMatcher(r, str, index));
})();

export const Css_BETWEEN_CURLY_BRACES = (() => {
    const quotes = new Set(["\"", "\'"]);
    return (str) => ((index) => BETWEEN(quotes, "{", "}", str, index));
})();

export const Css_NEXT_SEMICOLON = (() => {
    const quotes = new Set(["\"", "\'"]);
    return (str) => ((index) => NEXT_CHAR(quotes, ";", str, index));
})();

export const Css_NEXT_OPEN_CURLY_BRACE = (() => {
    const quotes = new Set([]);
    return (str) => ((index) => NEXT_CHAR(quotes, "{", str, index));
})();

export function Css_scope(uniqueIdent, rules) {
    const keyframes = [];
    const fail = (rules_1, index) => toFail(`Cannot parse CSS at ${index}: ..${rules_1.slice(index, (index + 8) + 1)}..`);
    const skipCurlyBracesFromOpenBrace = (scoped) => ((rules_2) => ((index_2) => {
        const matchValue = Css_BETWEEN_CURLY_BRACES(rules_2)(index_2);
        if (matchValue.tag === 0) {
            const index2 = matchValue.fields[1] | 0;
            return scope(scoped + rules_2.slice(index_2, (index2 - 1) + 1))(rules_2)(index2);
        }
        else {
            return fail(rules_2, index_2);
        }
    }));
    const skipCurlyBraces = (scoped_2) => ((rules_3) => ((index_3) => {
        const matchValue_1 = Css_NEXT_OPEN_CURLY_BRACE(rules_3)(index_3);
        if (matchValue_1.tag === 0) {
            const index2_1 = matchValue_1.fields[1] | 0;
            return skipCurlyBracesFromOpenBrace(scoped_2 + rules_3.slice(index_3, (index2_1 - 2) + 1))(rules_3)(index2_1 - 1);
        }
        else {
            return fail(rules_3, index_3);
        }
    }));
    const scope = (scoped_4) => ((rules_4) => ((index_4) => {
        const index2_2 = consumeWhitespace(rules_4, index_4) | 0;
        if (index2_2 >= rules_4.length) {
            return scoped_4 + rules_4.slice(index_4, rules_4.length);
        }
        else if (rules_4[index2_2] === "@") {
            const matchValue_2 = Css_IDENT(rules_4)(index2_2 + 1);
            if (matchValue_2.tag === 1) {
                return fail(rules_4, index2_2 + 1);
            }
            else {
                const statement = matchValue_2.fields[0];
                const index2_3 = matchValue_2.fields[1] | 0;
                let matchResult;
                if (!isEmpty(statement)) {
                    switch (head(statement)) {
                        case "media": {
                            if (isEmpty(tail(statement))) {
                                matchResult = 0;
                            }
                            else {
                                matchResult = 3;
                            }
                            break;
                        }
                        case "supports": {
                            if (isEmpty(tail(statement))) {
                                matchResult = 0;
                            }
                            else {
                                matchResult = 3;
                            }
                            break;
                        }
                        case "keyframes": {
                            if (isEmpty(tail(statement))) {
                                matchResult = 1;
                            }
                            else {
                                matchResult = 3;
                            }
                            break;
                        }
                        case "font-face": {
                            if (isEmpty(tail(statement))) {
                                matchResult = 2;
                            }
                            else {
                                matchResult = 3;
                            }
                            break;
                        }
                        case "page": {
                            if (isEmpty(tail(statement))) {
                                matchResult = 2;
                            }
                            else {
                                matchResult = 3;
                            }
                            break;
                        }
                        default:
                            matchResult = 3;
                    }
                }
                else {
                    matchResult = 3;
                }
                switch (matchResult) {
                    case 0: {
                        const matchValue_3 = Css_NEXT_OPEN_CURLY_BRACE(rules_4)(index2_3);
                        if (matchValue_3.tag === 0) {
                            const index2_4 = matchValue_3.fields[1] | 0;
                            const scoped_5 = scoped_4 + rules_4.slice(index_4, (index2_4 - 2) + 1);
                            const matchValue_4 = Css_BETWEEN_CURLY_BRACES(rules_4)(index2_4 - 1);
                            if (matchValue_4.tag === 0) {
                                const index3 = matchValue_4.fields[1] | 0;
                                return scope(((scoped_5 + "{") + scope("")(rules_4.slice(index2_4, (index3 - 2) + 1))(0)) + "}")(rules_4)(index3);
                            }
                            else {
                                return fail(rules_4, index2_4);
                            }
                        }
                        else {
                            return fail(rules_4, index2_3);
                        }
                    }
                    case 1: {
                        const index2_5 = consumeWhitespace(rules_4, index2_3) | 0;
                        const matchValue_5 = Css_IDENT(rules_4)(index2_5);
                        let matchResult_1, ident, index3_1;
                        if (matchValue_5.tag === 0) {
                            if (!isEmpty(matchValue_5.fields[0])) {
                                if (isEmpty(tail(matchValue_5.fields[0]))) {
                                    matchResult_1 = 0;
                                    ident = head(matchValue_5.fields[0]);
                                    index3_1 = matchValue_5.fields[1];
                                }
                                else {
                                    matchResult_1 = 1;
                                }
                            }
                            else {
                                matchResult_1 = 1;
                            }
                        }
                        else {
                            matchResult_1 = 1;
                        }
                        switch (matchResult_1) {
                            case 0: {
                                void (keyframes.push(ident));
                                return skipCurlyBraces((((scoped_4 + rules_4.slice(index_4, (index2_5 - 1) + 1)) + uniqueIdent) + "-") + ident)(rules_4)(index3_1);
                            }
                            default:
                                return fail(rules_4, index2_5);
                        }
                    }
                    case 2:
                        return skipCurlyBraces(scoped_4 + rules_4.slice(index_4, (index2_3 - 1) + 1))(rules_4)(index2_3);
                    default: {
                        const matchValue_6 = Css_NEXT_SEMICOLON(rules_4)(index2_3);
                        if (matchValue_6.tag === 0) {
                            const index2_6 = matchValue_6.fields[1] | 0;
                            return scope(scoped_4 + rules_4.slice(index_4, (index2_6 - 1) + 1))(rules_4)(index2_6);
                        }
                        else {
                            return fail(rules_4, index2_3);
                        }
                    }
                }
            }
        }
        else {
            const matchValue_7 = Css_NEXT_OPEN_CURLY_BRACE(rules_4)(index2_2);
            if (matchValue_7.tag === 0) {
                const index3_2 = matchValue_7.fields[1] | 0;
                const selectors = join(",", map((selector) => {
                    const index_1 = consumeWhitespace(selector, 0) | 0;
                    if (selector.slice(index_1, (index_1 + 4) + 1) === ":host") {
                        return ((selector.slice(0, (index_1 - 1) + 1) + ".") + uniqueIdent) + selector.slice(index_1 + 5, selector.length);
                    }
                    else {
                        return (((selector.slice(0, (index_1 - 1) + 1) + ".") + uniqueIdent) + " ") + selector.slice(index_1, selector.length);
                    }
                }, split(rules_4.slice(index2_2, (index3_2 - 2) + 1), [","], void 0, 0)));
                return skipCurlyBracesFromOpenBrace((scoped_4 + rules_4.slice(index_4, (index2_2 - 1) + 1)) + selectors)(rules_4)(index3_2 - 1);
            }
            else {
                return fail(rules_4, index2_2);
            }
        }
    }));
    const rules_6 = scope("")(replace(/\/\*(?:(?!\*\/)[\s\S])*\*\//gu, rules, ""))(0);
    if (keyframes.length === 0) {
        return rules_6;
    }
    else {
        return replace(/(animation(?:-name)?:)([^};]+)/gu, rules_6, (m) => ((m[1] || "") + replace(/[\w\-]+/gu, m[2] || "", (m_1) => {
            const ident_1 = m_1[0];
            return contains_1(ident_1, keyframes, {
                Equals: (x, y) => (x === y),
                GetHashCode: stringHash,
            }) ? ((uniqueIdent + "-") + ident_1) : ident_1;
        })));
    }
}

